const en = {
    btnLogin: "Log in",
    btnChangeUser: "Change User",
    msgLoginTimeout: "You have been logged off automatically after timeout.",
    msgProfileNotFound: "Profile not found",
    msgLoginFail: "Incorrect username or password, please try again.",
    msgLockAccount: "Account is locked, please reset your password to unlock it.",
    msgUserNotActivate: "User not activated",
    msgChangeUserFail: "Incorrect username or user is not activated",
    msgChangeUserNoAccess: "You do not have change user right",
    lbRegister: "Registration",
    msgHaveSISAccount: "Do you have a SIS Account?",
    lbRegisterWithSIS: "Yes",
    lbRegisterWithoutSIS: "No",
    lbForgotPW: "Forgot Password",
    lbResetPW: "Reset Password",
    lbTermsOfUse: "Terms of Use",
    lbTermsOfUseContent: "Legal Disclaimer: This system may not be used as the sole information tool by which future claims against any member of the M+R Spedag Group and its affiliates and/or agents services performance may be derived. Under no circumstances shall any member of the M+R Spedag Group and its affiliates and/or agents be liable for any damage including but not limited to special or consequential damages that result from the access or the use of, or the inability to access or use this system. The data contained in this system is provided without any warranty of any kind, either expressed or implied, as to their accuracy, reliability or completeness.",
    lbSignUp: "Sign Up",
    privacyPolicy: {
        header: "Privacy Policy",
        privacyPolicy1: "This privacy policy applies exclusively to the M+R Spedag Group website www.mrspedag.com.",
        privacyPolicy2: "Our goal is to ensure that your personal data is processed in compliance with the law. Our websites may contain links to external websites for which this data protection declaration is not valid.",
        personalData: "Personal data",
        personalDataContent: "We do not systematically collect personal data on our websites. It is possible that a visitor to our website transmits personal data to us, e.g. by using a contact form to contact us.",
        useOfPersonalData: "Use of personal data",
        useOfPersonalDataContent: "By submitting personal data via online forms, e-mail or similar, the user agrees to the processing of the data. This may be necessary to answer an enquiry or provide a service. If personal data is transmitted to us, it will only be used for internal processes and administered in compliance with legal requirements.",
        deletionOfPersonalData: "Deletion of personal data",
        deletionOfPersonalDataContent: "The data subject may request access to personal data and request any changes or deletion of personal data. The M+R Spedag Group will examine the request and implement it in accordance with legal requirements.",
        contract: "Contact",
        contractContent: "Information about your data can be sent to you via email: info@mrspedag.com",
        passongOfPersonalData: "Passing on of personal data",
        passongOfPersonalDataContent: "In principle, personal data will not be passed on to third parties. However, we reserve the right to take this step if this serves to comply with applicable laws and regulations and/or we consider this necessary to preserve our rights and/or to protect our property.",
        cookies: "Cookies",
        cookiesContent: "The full functionality of this website depends on the use of  cookies. These are small files that are stored on the visitor’s PC. Cookies are used to store certain settings or preferences. These cookies do not pose any risk to the user’s computer.",
        webAnalysisTools: "Web Analysis Tools",
        webAnalysisToolsContent: "Our website uses Google Analytics, a web analysis service of Google Inc. Information about the use of the websites, including the IP addresses of visitors, is transmitted to a Google server (usually in the USA) and stored there. This service is used to evaluate the use of the websites. Google provides add-ons for the most common web browsers under the web link “https://tools.google.com/dlpage/gaoptout”, with which Google Analytics can be deactivated.",
    },
    lbMR: "M+R SPEDAG GROUP",
    lblConfirm: "Confirm",
    lblCancel: "Cancel",
    lblOK: "OK",
    lblBack: "Back",
    lblEdit: "Edit",
    lblOMSPO: "OMS PO",
    lblNext: "Next",
    lblSubmit: "Submit",
    lblSearch: "Search",
    lblApply: "Apply",
    lblCreate: "Create",
    lblYes: "Yes",
    lblNo: "No",
    lbAcceptRejectAllText: "Accept / Reject All Changes",
    msgConfirmAcceptRejectAll: "Please review all the changes before accepting or rejecting them",
    lbShowLatestEBookingText: "Show Latest E-Booking",
    lbRejectAll: "Reject All",
    lbAcceptAll: 'Accept All',
    lblContinueSave: "Continue Save",
    lblResetAndSave: "Reset and Save",
    lblUserType: "Role",
    lblLocation: "Location",
    lblLocationToolTip: "Location of Shipper",
    lblReferred: "Referred by",
    lblReferredToolTip: "Name of M+R Staff",
    lblReferredOffice: "Referred by Office",
    lblReferredOfficeToolTip: "Office of the above M+R Staff",
    lbUserID: "User ID",
    lbLoinID: "Login ID",
    lbLoinIDToolTip: "Please enter your email address as your Login ID",
    msgLoinIDRequired: "Please enter Login ID",
    lbPassword: "Password",
    msgPasswordRequired: "Please enter Password",
    msgStrongPWRequired: "The password must contain at least 8 characters, a number, an uppercase and a lowercase letter.",
    msgPwNotMatch: "Passwords do not match",
    msgInvalidPassword: "Invalid password",
    msgInvalidCurrentPassword: "Invalid Current Password",
    msgRepeatPassword: "Password has been used before, please use a new password.",
    msgForceChangePassword: "Your password is expired, please change your password now.",
    msgActivateACSuccessfullly: "Please login to start the new experience",
    lbConfirmPassword: "Confirm Password",
    msgConfirmPasswordRequired: "Please confirm your password",
    msgPasswordInconsistent: "Two passwords that you enter is inconsistent",
    lbFamilyName: "Family Name",
    msgFamilyNameRequired: "Please enter Family Name",
    lbGivenName: "Given Name",
    msgGiveNameRequired: "Please enter Given Name",
    lbAddress: "Address",
    msgAddressRequired: "Please enter Address",
    lbZipPostal: "Zip / Postal",
    lbCountryCode: "Country Code",
    lbFDCountryCode: "FD Country Code",
    lbEmail: "E-mail",
    msgEmailRequired: "Please enter E-mail",
    msgEmailInvalid: "The input is not valid E-mail",
    lbPhoneNumber: "Phone Number",
    msgPhoneNumberRequired: "Please enter Phone Number",
    msgCompanyRequired: "Please enter Company",
    lbFax: "Fax",
    msgFaxRequired: "Please enter Fax",
    lbCompany: "Company",
    lbPosition: "Position",
    lbDepartment: "Department",
    lbSalesCode: "Sales Code",
    lbLanguage: "Language",
    msgLanguageRequired: "Please enter Language",
    lbProfile: "Profile",
    msgProfileRequired: "Please select a Profile",
    lbUserLevel: "User Level",
    lbRole: "Role",
    msgRoleRequired: "Please enter Role",
    lbPartyAccessType: "Party Access Type",
    msgPartyAccessTypeRequired: "Please enter Party Access Type",
    msgPartyGroupRequired: "Please enter Party Group",
    lbTradelane: "Tradelane",
    lbAccessibleSalesCode: "Accessible Sales Code",
    lbAccessibleSalesCode_Tooltips: "Whenever there is a slash in front of a salesman's name, it means that salesman/ sales code is not active in the system. Please pay attention to adding an inactive sales code as Accessible Sales Code.",
    msgLoginIdDuplicate: "Login ID already exists",
    msgEnterSISID: "To sign up, please enter current SIS Login Name and Password",
    msgSISIDVerified: "SIS account has been verified. Enter details below to sign up your personal account.",
    lbSISLoginID: "SIS Login Name",
    msgInvalidSISLoginID: "The SIS Login Name or Password is invalid, please enter a valid Login Name and Password",
    msgCannotMapUserProfiles: "Cannot map user profile with SIS ID, please register a new account without SIS ID",
    msgLoginIDAleadyRegistered: "Login ID already registered",
    msgSISLoginIDRequire: "Please enter SIS Login ID",
    lbPageSize: "Page Size",
    btnSave: "Save",
    btnRemindMeLater: "Remind me later",
    msgUpdatePassword: "Please update your password",
    msgPasswordExpireInDays: "Your password will expire in {{password_expire_in_days}} days. Please change the password.",
    msgPasswordExpireSoon: "Passsword will expire soon",
    msgPasswordExpired: "Your password has expired. Please change the password",
    btnCancel: "Cancel",
    btnOk: "OK",
    btnApply: "Apply",
    btnReset: "Reset",
    btnDelete: "Delete",
    btnClose: "Close",
    lbContainerSize: "Container Size",
    msgContainerSizeRequired: "Please select a Container Size",
    msgContainerQtyRequired: "Please select a Container Qty",
    lbQty: "Qty",
    lbCargoReadyDate: "Cargo Ready Date",
    lbDepartureDate: "Departure Date",
    lbArrivalDate: "Arrival Date",
    lbTransshipmentPort: "Transshipment Port",
    lbDepartureFrom: "Departure From",
    lbPOL: "Port of Loading (POL)",
    msgPOLRequired: "Please select a Port of Loading (POL)",
    msgPOLHBLRequired: "Please enter Port of Loading (POL) in HBL",
    lbETD: "ETD",
    msgETDRequired: "Please enter ETD",
    msgETDInvalid: "Invalid ETD",
    lbPOD: "Port of Discharge (POD)",
    msgPODRequired: "Please select a Port of Discharge (POD)",
    msgPODHBLRequired: "Please enter Port of Discharge (POD) in HBL",
    lbETA: "ETA",
    msgETARequired: "Please enter ETA",
    msgETAInvalid: "Invalid ETA",
    lbMotherVesselVoyage: "Mother Vessel Voyage",
    lbFeederVesselVoyage: "Feeder Vessel Voyage",
    msgPortRequired: "Please select Transshipment Port",
    lbCarrier: "Carrier",
    lbCopyFromSailingOption: "Copy from Sailing Option",
    lbCopyFromSailingOptionTooltip: "Select and apply the Sailing from Sailing Option",
    lbIsTBCSailing: 'Is TBC Sailing',
    lbType: 'Type',
    msgCarrierRequired: "Please enter Carrier",
    lbColoader: "Co-loader",
    msgColoaderRequired: "Please enter Co-loader",
    lbPLD: "Place of Delivery (PLD)",
    msgPLDRequired: "Please select a Place of Delivery (PLD)",
    msgPLDHBLRequired: "Please enter Place of Delivery (PLD) in HBL",
    msgShipModeRequired: "Please select a Ship Mode",
    lbFD: "Final Destination (FD)",
    msgFDRequired: "Please select a Final Destination (FD)",
    msgFDHBLRequired: "Please enter Final Destination (FD) in HBL",
    msgFDCountryRequired: "Please select a Final Destination Country",
    msgInvalidCountryCode: "Invalid Country Code",
    lbMainOffice: "Main Office",
    msgUpdatePOLFromSailing: "You have selected sailing option with POL {{sailing_pol_name}}. The current booking POL is {{so_pol_name}}. Are you sure you want to select this {{sailing_pol_name}} sailing and update booking POL to {{sailing_pol_name}}? Please note that booking cannot be confirmed if sailing schedule's POL does not match booking's POL.",
    msgUpdatePODFromSailing: "You have selected sailing option with POD {{sailing_pod_name}}. The current booking POD is {{so_pod_name}}. Are you sure you want to select this {{sailing_pod_name}} sailing and update booking POD to {{sailing_pod_name}}? Please note that booking cannot be confirmed if sailing schedule's POD does not match booking's POD.",
    lbLegETD: "Leg {{leg}} ETD",
    lbLegVessel: "Leg {{leg}} Vessel Name",
    lbLegVoyage: "Leg {{leg}} Voyage Code",
    lbLegServiceName: "Leg {{leg}} Service Name",
    lbLegServiceCode: "Leg {{leg}} Service Code",
    lbLegPort: "Leg {{leg}} Port",
    lbCFS: "CFS (LCL)",
    lbCY: "CY",
    lbCyClosingDate: "CY Closing Date",
    lbVGM: "VGM",
    lbVgmCutOffDate: "VGM Cutoff Date",
    lbSI: "SI",
    lbSiCutOffDate: "SI Cutoff Date",
    lbRoutes: "Routes",
    lbRoute: "Route",
    lbServiceName: "Service Name",
    lbServiceCode: "Service Code",
    lbMotherServiceName: "Mother Service Name",
    lbMotherServiceCode: "Mother Service Code",
    msgServiceCodeRequired: "Please enter a service code",
    lbVessel: "Vessel",
    lbVesselName: "Vessel Name",
    lbVesselIMONumber: "Vessel IMO Number",
    msgVesselNameRequired1: "Please enter vessel",
    msgVesselNameRequired2: "Please select a mother vessel from transshipment",
    lbVoyage: "Voyage",
    lbVoyageCode: "Voyage Code",
    msgVoyageCodeRequired1: "Please enter voyage",
    msgVoyageCodeRequired2: "Please select a mother vessel from transshipment",
    lbVesselImoNumber: "IMO Number",
    lbVesselOperator: "Operator",
    lbTransshipmentCode: "Transshipment Port",
    lbUserProfile: "User Profile",
    lbUserPassword: "User Password",
    lbNewPassword: "New Password",
    lbCurrentPassword: "Current Password",
    lbConfirmNewPassword: "Confirm New Password",
    lbContractType: "Contract Type",
    lbContractNo: "Contract No.",
    lbCarrierBookingNo: "Carrier Booking No.",
    lbCarrierBookingDate: "Carrier Booking",
    lbCarrierConfirmBookingDate: "Carrier Confirm Booking",
    lbSOReference: 'S/O Reference',
    lbSOReferenceTooltip: 'Reference no. of shipping order',
    lbSORefNil: 'S/O Reference Not Applicable',
    lbSORefNilTooltip: 'Tick this checkbox if required Reference no. is not applicable to this booking',
    msgUpdatePwSuccessfully: "Updated password successfully",
    msgUpdatePwFail: "Fail to update password",
    msgPwNotMatch: "Password not match",
    msgUpdatedUserProfileSuccessfully: "Updated user profile successfully",
    msgUpdatUserProfileFail: "Fail to update user profile",
    msgUserProfileNotUnique: "Profile Type should be unique",
    msgUserProfileNotAssignedFuncRole: "Please assign at leaset 1 System Function Role for each profile",
    msgVirusScanning: "(Scanning Virus...) {{file_name}}",
    msgLoading: "Loading...",
    msgSaving: "Saving...",
    errSaveUnknown: "Unable to save with unknown reason.",
    msgSureToDelete: "Sure to delete?",
    msgNoRecordFound: "No record found",
    msgNoRateRecordFound: "No rate record found",
    msgRateApiError: "Rate search failure. Please try again. {{error_message}}",
    msgNoDashboardItem: "No dashboard item found.",
    msgLoadAutoSave: "An auto save record is found (saved at {{datetime}}), do you want to load it?",
    msgAutoSaveLoaded: "auto save loaded",
    msgAutoSaveSaved: "The booking has been auto-saved",
    msgSureToLeave: "Are you sure you want to leave?",
    msgSureToReset: "Sure to reset to default settings?",
    lblSelectColumn: "Please select a column",
    lblColumnOption: "Column Options",
    lblColumn: "Columns",
    msgColumnOptions: "Add or remove columns with maximum 30 columns to be shown. To change the column order, drag and drop a field.",
    lblAddColumn: "Add a Column",
    errExportFailed: "Unable to export file.",
    lblFrom: "From",
    lblTo: "To",
    lblMotherVessel: "Mother Vessel",
    lblMotherVesselName: "Mother Vessel Name",
    lblMotherVesselIMONumber: "Mother Vessel IMO Number",
    lblMotherVoyageCode: "Mother Voyage Code",
    msgMotherVesselRequired1: "Please enter Mother Vessel Voyage",
    msgMotherVesselRequired2: "Please select Mother Vessel Voyage",
    lblTransshipmentCount: "Transshipment Count",
    lbMotherVesselLeg: "Mother Vessel Leg",
    colOffice: "Office",
    pnlRelatedSailingSchedule: "Related Sailing Schedules",
    pnlRelatedBookings: "Related Bookings",
    pnlRelatedConsol: "Related Consol",
    pnlSailingHistory: "Sailing Schedule History",
    msgChangeETDETAWarning: "You are overriding the ETD / ETA and switching into a Manual sailing schedule. Any automatic update of this sailing from the data provider / carriers will also be disabled. Please make sure you are entering the correct information.",
    colFeederVessel: "Feeder Vessel",
    colFeederVoyage: "Feeder Voyage",
    colPLD: "Place of Delivery (PLD)",
    colPLDEta: "PLD ETA",
    msgConfirmCreateSailingSchedule: "Sailing schedule with the same carrier, vessel, voyage, POL or POD exists. Do you want to continue?",
    msgConfirmRelatedSailingSchedule: "The sailing schedule (including the Cut-off dates) of all related bookings will be updated. Are you sure to continue? Please consult the relevant operation teams if needed.",
    msgConfirmRelatedShipmentsAndConsol: "The sailing schedule (including the Cut-off dates) of all related bookings and consol will be updated. Are you sure to continue? Please consult the relevant operation teams if needed.",
    colField: "Field",
    colValue: "Value",
    lblVesselVoyage: "Vessel Voyage",
    msgSubmitting: "Submitting",
    msgSubmitForgotPwSuccessfully: "Submit successfully. An Email will be sent to reset password.",
    msgSubmitForgotPwFail: "No record found.",
    msgResetPwSuccessfully: "Reset successfully.",
    msgResetPwFail: "Fail to reset password.",
    msgRecordNotLatest: "This record is not updated. The record has been changed by another user.",
    msgRecordNotLatestOrSOHandleByOPS: "This shipment is updated by other ousers, or is cancelled, or already handled by OPS.",
    msgSOHandleByOPS: "This shipment is already handled by OPS.",
    msgExceedMaxFileSize: "The file exceeds the size limit ({{max_file_size}} MB) per file",
    msgIncorrectInput: "Incorrect Input",
    msgSavedSuccess: "Saved",
    lblColumnOptions: "Column Options",
    lblRelatedSailingScheduleToolTip1: "Sailings with same mother vessel and voyage, departing from different POLs but arriving at the same POD. E.g. Mother Vessel first loading at Yantian, then load at Xiamen and discharge at Long Beach.",
    lblRelatedSailingScheduleToolTip2: "Or sailing with same mother vessel and voyage, departing from the same POL but arriving at different PODs.E.g.Mother Vessel departing from Yantian, then first discharge at Long Beach and discharge at Vancouver.",
    msgTBCSailingWarning: "The ETD of TBC Sailing Schedule will be automatically updated on every 16th day of the month to the last day of next month.",
    lblActive: 'Active',
    lblInactive: 'Inactive',
    msgVirusDetected: "File upload failure - suspected virus detected",
    lbSearchRate: "Search Rate",
    lbPortOrDoorRate: 'Port / Door Rate',
    lbPortOrDoorRateHover: 'Port-to-Port: search rate by origin = POL, destination = POD\nPort-to-Door: search rate by origin = POL, destination = PLD\nDoor-to-Port: search rate by origin = POR, destination = POD\nDoor-to-Door: search rate by origin = POR, destination = PLD',
    lbContractInfo: 'Contract Info',
    lbRateSource: 'Rate Source',
    lbInfoForSearchRateOnly: "Below information is required for searching rate only",
    lbRateMapDate: 'Cargosphere Rate Date',
    lbRateMapDateHover: 'The Cargosphere Rate Date of "N day(s) after sailing\'s ETD" is used to search for applicable Cargosphere rate.\nOP can adjust Cargosphere Rate Date to be the estimated ATD/ ETB/ CY Gate-in Date according to carrier\'s requirement.\nFor US sailing, Cargosphere Rate Date cannot be amended after CY Gate-in Date is input and equals to the latest CY Gate-in Date of all containers.',
    lbPanelRateMapDateHover: 'The Cargosphere Rate Date is used to refresh applicable Cargosphere rate.\nOP can adjust Cargosphere Rate Date to be the estimated ATD/ ETB/ CY Gate-in Date according to carrier\'s requirement.\nFor US sailing, Cargosphere Rate Date cannot be amended after CY Gate-in Date is input and equals to the latest CY Gate-in Date of all containers.',
    menu: {
        "Home": "Home",
        "Dashboard": "Dashboard",
        "Booking": "Booking",
        "New e-Booking Pending": "New e-Booking Pending",
        "Cancel e-Booking Pending": "Cancel e-Booking Pending",
        "Amend e-Booking Pending": "Amend e-Booking Pending",
        "New Manual Booking Pending": "New Manual Booking Pending",
        "Booking Confirmed": "Booking Confirmed",
        "Booking Approval": "Booking Approval",
        "Request For Approval": "Request For Approval",
        "Pending For Customer Approval": "Pending For Customer Approval",
        "Booking Rejected": "Booking Rejected",
        "Booking Advice": "Booking Advice",
        "Setting": "Setting",
        "User Profile": "User Profile",
        "User Maintenance": "User Maintenance",
        "Change Password": "Change Password",
        "Admin": "Admin",
        "Party Maintenance": "Party Maintenance",
        "Party Group Maintenance": "Party Group Maintenance",
        "Sailing Schedule Maintenance": "Sailing Schedule Maintenance",
        "Sign Out": "Sign Out",
        "ChangeUser": "Change User",
        "TradeLane Maintenance": "TradeLane Maintenance",

        "AP Upload Document": "AP Upload Document",
        "AP Pending Creation": "AP Pending Creation",
        "AP Pending Approval": "AP Pending Approval",
        "AP Released": "AP Released",
        "AP Pending Posting": "AP Pending Posting",
        "AP Posted": "AP Posted",
        "AP Voided": "AP Voided",
        "AP Settled": "AP Settled",
        "Account Payable (AP)": "Account Payable (AP)",
        "AR Pending Creation": "AR Pending Creation",
        "AR Pending Printing": "AR Pending Printing",
        "AR Pending Posting": "AR Pending Posting",
        "AR Posted": "AR Posted",
        "AR Reversed": "AR Reversed",
        "AR Settled": "AR Settled",
        "Account Receivable (AR)": "Account Receivable (AR)",
        "Search Invoice": "Search Invoice",
        "Search AP": "Search AP",
        "Create New AP": "Create New AP",
        "AP Pending Preparation": "AP Pending Preparation",
        "AP Pending Approval (1st level)": "AP Pending Approval (1st level)",
        "AP Pending Approval (2nd level)": "AP Pending Approval (2nd level)",
        "AP Pending AP Ref to be entered": "AP Pending AP Ref to be entered",
        "AR / AP Pending Account Period Close": "AR / AP Pending Account Period Close",
        "AR / AP Pending Account Period New Close": "AR / AP Pending Account Period New Close",
        "AP Reversed": "AP Reversed",
        "AP Account Period Closed": "AP Account Period Closed",
        "AP Unsettled": "AP Unsettled",
        "Search AR": "Search AR",
        "Create New AR": "Create New AR",
        "AR Pending Preparation": "AR Pending Preparation",
        "AR Account Period Closed": "AR Account Period Closed",
        "AR Unsettled": "AR Unsettled",
        "Search AP / AR": "Search AP / AR",

        "Analytics": "Analytics",
        "Web Invoice GP": "Web Invoice GP",

        "Template Library": "Template Library",
    },
    so: {
        msgMainHouseDocIcon: 'When Job# is assigned in OPMS, OPMS will send the shipment data to SIS (other applications/ EDI will make use of the data which received by SIS) based on the Main House Doc.\nFor other created House Doc (if any), they will not be sent to SIS and used in any other applications or EDI.',
        msgPayableAtCountryCity: 'Only if the shipper specifically asks for showing a specific country/ region/ city (i.e. "Switzerland" or "Basel" etc.), otherwise, the Freight Payable At should remain as either \'DESTINATION\' or \'ORIGIN\'.',
        lbPayableAtCountryCity: 'A specific country/ region/ city',
        lbMainHouseDoc: 'Main House Doc',
        lbAMSSCAC: 'AMS SCAC',
        lbAMSHBLNo: 'AMS HBL No.',
        lbManualSailing: 'Manual Sailing',
        lbTBCSailing: 'TBC Sailing',
        lblHeader: "Header",
        lblDetails: "Details",
        lblISF: "ISF",
        lblFiling: "Filing",
        lblDocumentUpload: "Document Upload",
        lblDocumentUploadHistory: "Document Upload History",
        lbVGMEDIHistory: 'VGM EDI History',
        lblSI: "Shipping Instruction",
        lblConfirmBooking: "Confirm Booking",
        lblCarrierBooking: 'Carrier Booking',
        lblShippingOrder: "Shipping Order",
        lblShipper: "Shipper",
        lblShipperToolTip: "Shipper is the party which provides the written shipping order. It may not necessarily be the supplier of the cargo.\n\nIf company A provides a shipper order requesting to issue a BL showing company B as the shipper, written consent of company B must be provided. Or company A must prove that it is entitled by company B to ship cargo in the name of company A.",
        lblShipperCityToolTip: 'Please enter the City to search for the appropriate City. In case no results found, please enter Country and Province, e.g. China Hebei, Viet Nam An Giang. Then, a list of cities will be available for you to choose.\nThe selected City will be used for customs filing(if applicable) and wouldn\'t be shown in HBL.',
        lblShipperRegionCountryCode: 'Country / Region',
        lblShipperPlaceHolder: "Shipper (code)",
        msgShipperRequired: "Please select a Shipper",
        msgShipperAddressRequired: 'Please input Shipper Address',
        msgShipperCityRequired: 'Please input Shipper City',
        msgPhoneRequired: 'Please input Phone',
        lbConsigneeNo: 'Consignee#',
        lbIORNo: 'IOR#',
        lblCustomer: "Customer",
        lblCustomerTooltip: "Please select the Customer (e.g. Key Account) of this booking in this field. If it is not applicable, please input 'Other'.",
        lblCustomerPlaceHolder: "Customer (code)",
        msgCustomerRequired: "Please select a Customer",
        lblConsignee: "Consignee",
        lblConsigneeToolTip: "The consignee name and address as instructed by the shipper must be shown on the BL. The consignee may not necessarily be the recipient of the shipment. For instance, it is possible that a shipment is from point A to point B but the consignee on the BL is located at point C.",
        lblConsigneePlaceHolder: "Consignee (code)",
        msgConsigneeRequired: "Please select a Consignee",
        msgConsigneeAddressRequired: 'Please input Consignee Address',
        msgConsigneeCityRequired: 'Please input Consignee City',
        lblMOT: "Mode of Transportation",
        msgMOTRequired: "Please select the Mode of Transportation",
        lblMOTSea: "Sea",
        lblMOTAir: "Air",
        lblMOTRail: "Rail",
        lblService: "Service",
        msgServiceRequired: "Please select a Service",
        lblServiceFCL: "FCL",
        lblServiceLCL: "LCL",
        lblServiceBulk: "Bulk",
        lblPODDelivery: "Port / Door Delivery",
        msgPODDeliveryRequired: "Please select a Port / Door Delivery",
        lblPODDeliveryP2p: "Port-to-Port",
        lblPODDeliveryP2d: "Port-to-Door",
        lblPODDeliveryD2p: "Door-to-Port",
        lblPODDeliveryD2d: "Door-to-Door",
        lblShipperReference: "Shipper Reference",
        lblShipperReferenceTooltip: "Reference no. of Shipper",
        lblShipperReferencePlaceHolder: "Shipper Reference",
        lblAgentReference: "Agent Reference",
        lblAgentReferenceTooltip: "Reference no. of Agent",
        lblAgentReferencePlaceHolder: "Agent Reference",
        lblConsigneeReference: "Consignee Reference",
        lblConsigneeReferenceTooltip: "Reference no. of Consignee",
        lblConsigneeReferencePlaceHolder: "Consignee Reference",
        lblMRReference: "M+R Reference",
        lblMRReferenceTooltip: "Reference no. of M+R",
        lblMRReferencePlaceHolder: "M+R Reference",
        lblBusinessType: "Business Type",
        msgBusinessTypeRequired: "Please select a Business Type",
        lblBusinessTypeNomination: "Nomination",
        lblBusinessTypeFreehand: "Freehand",
        lblNominationAgent: "Nomination Agent",
        msgNominationAgentRequired: "Please select a Nomination Agent",
        lblAgentPlaceHolder: "Agent (code)",
        lblReleaseAgent: "Release Agent",
        msgReleaseAgentRequired: "Please select a Release Agent",
        lblSalesman: "Salesman",
        msgSalesmanRequired: "Please select a Salesman",
        lbSOPInstruction: "SOP instructions",
        lbSOPInstructionContainerTitle: "Container Usage",
        lbSOPInstructionContainerContent: "Use of 20' needs approval by customer........",
        lbSOPInstructionRoutingTitle: "Routing Instructions",
        lbSOPInstructionRoutingContent: "1st priority carrier is........",
        msgFillInRequiredFields: "Please fill in all required fields.",
        errSONotFound: "Shipping Order ({{sono}}) is not found.",
        msgIncorrectCnsIORFormat: 'Please enter {{input_type}} with maximum length {{max_len}}',
        msgRateExternalContractTypeRequired: "Please select an External Contract Type",
        lblItemNo: "Item#",
        lblRemark: "Remark",
        lblItemQty: "Item qty",
        lblItemQtyUnit: "Item qty unit",
        lblPackageQty: "Package qty",
        lblPackageQtyUnit: "Package qty unit",
        lblGrossWeight: "Gross weight",
        lblNetWeight: "Net weight",
        lblCBM: "CBM",
        lblDocumentType: "Document Type",
        lblFile: "File",
        lblFileSource: "File Source",
        lblDragOrUpload: "Select / Drag a file",
        lblTiming: "Timing as per customer's requirement",
        lblSubmitBooking: "Submit Booking",
        msgISFMustComplete: "ISF must be completed for USA shipment\nENS must be completed for EU, Switzerland, Norway, Northern Ireland shipment",
        lblSeller: "Seller",
        lblManufacturer: "Manufacturer",
        lblBuyer: "Buyer",
        lblShipTo: "Ship To",
        lblContainerStuffingLocation: "Container Stuffing Location",
        lblConsolidator: "Consolidator",
        lblISFCompanyName: "Name",
        msgSellerNameRequired: "Please input Seller Name",
        lblSellerPlaceHolder: "Seller Name",
        msgManufacturerNameRequired: "Please input Manufacturer Name",
        lblManufacturerNamePlaceHolder: "Manufacturer Name",
        msgBuyerNameRequired: "Please input Buyer Name",
        lblBuyerPlaceHolder: "Buyer Name",
        msgShipToNameRequired: "Please input Ship To Name",
        lblShipToPlaceHolder: "Ship To Name",
        msgContainerStuffingLocationNameRequired: "Please input Container Stuffing Location Name",
        lblContainerStuffingLocationPlaceHolder: "Container Stuffing Location",
        msglblConsolidatorNameRequired: "Please input Consolidator Name",
        lbllblConsolidatorPlaceHolder: "Consolidator Name",
        lblAddress1: "Address 1",
        msgAddress1Required: "Please input Address 1",
        lblAddress1PlaceHolder: "Address 1",
        lblAddress2: "Address 2",
        msgAddress2Required: "Please input Address 2",
        lblAddress2PlaceHolder: "Address 2",
        lbCityStateZip: 'City / State / Zip',
        lbCityStateZipTooltip: 'For STATE,\nif Country = "US": Mandatory, size = 2\nif Country = "CA": Mandatory, size = 2\nothers: Optional\n\nFor ZIP (note: format: 9 is digit, A is letter)\nif Country = "US": Mandatory, 2 formats - 5 digits "99999" e.g. 18235 or 5 digits + hyphen + 4 digits "99999-9999" e.g. 18235-2239\nif Country = "CA": Mandatory, format "A9A 9A9" e.g.M4L 1V2\nif Country = "CN": Mandatory, 6 digits "999999" e.g. 200070\nif Country = "JP": Optional. If ZIP is entered, 3 digits+hyphen+4 digits "999-9999"  e.g. 100-0005\nothers: Optional, no validation',
        lblCity: "City",
        lblState: 'State',
        lblZip: 'Zip',
        lbEORINoTooltip: 'The EORI# is a code that is available to all "Economic Operators" that are based in the European Union.\nThe EORI# is mandatory for Economic Operators that are filing customs entries for importation of goods into the EU, or are exporting from the EU.\nEconomic Operators not based in the EU must also file for an EORI# if they are importing goods into the EU, or are exporting from the EU.\nEconomic Operators not based in the EU must NOT file for an EORI# if their incoming goods are only transiting through the EU.\nEconomic Operators that are not based in the EU but are selling goods to / exporting goods to the EU must NOT file for an EORI#.\nThe format of an EORI# should be 2 letters followed by up to 15 alphanumeric characters(e.g.: DE123456789012345).\nAs the EORI# is not applicable to Switzerland, Norway and Northern Ireland, please enter the identification number(like the local Tax ID) as the EORI#.',
        msgCityRequired: "Please input City",
        msgStateRequired: "Please input State with length equal to 2",
        msgIncorrectStateFormat: 'State format not correct',
        msgZipRequired: "Please input Zip",
        msgIncorrectZipFormat: 'Zip format not correct',
        lblCityPlaceHolder: "City",
        lblStatePlaceHolder: "State",
        lblZipPlaceHolder: "Zip",
        lblCountry: "Country",
        msgCountryRequired: "Please input Country",
        lblCountryPlaceHolder: "Country",
        lblCountryOfOrigin: "Country of Origin",
        msgCountryOfOriginRequired: "Please input Country of Origin",
        lblCountryOfOriginPlaceHolder: "Country of Origin",
        lblHTSCode: "HTS Code",
        msgHTSCodeRequired: "Please input HTS Code",
        lblHTSCodePlaceHolder: "HTS Code",
        lblAddISFCompany: "Add a {{type}}",
        lblRemoveISFCompany: "Delete this {{type}}",
        lbModeOfTransportation: 'Mode of Transportation',
        lbBookingPOL: 'Booking POL',
        lbBookingPOD: 'Booking POD',
        lbBookingFD: 'Booking FD',
        lbBookingDate: 'Booking Date',
        lbExpectedCargoReadyDate: 'Expected Cargo Ready Date',
        lbETD: 'ETD',
        lbPODETA: 'POD ETA',
        lbFilingType: 'Filing Type',
        lbFilingTypeTooltip: 'Tick below checkbox to select filing type require to send EDI to Tradetech and Customs. Please ensure Job No. is assisgned to trigger EDI',
        colSoNo: "SO#",
        colPoNo: "PO#",
        colModeOfTransport: "Mode of Transport",
        colBookingType: "Booking Type",
        colDocumentUploaded: "Document Uploaded",
        colHblNo: 'HBL#',
        colJobNo: 'Job#',
        colHandleBy: 'Handle by',
        colShipper: 'Shipper',
        colShipperMaster: 'Shipper (Master)',
        colConsignee: 'Consignee',
        colConsigneeMaster: 'Consignee (Master)',
        colConsigneeCode: 'Consignee Code',
        colReleaseAgent: 'Release Agent',
        colNominationAgent: 'Nomination Agent',
        colBookingDate: 'Booking Date',
        colExFactoryDate: 'Ex-factory Date',
        colEarliestShipDate: 'Earliest Ship Date',
        colLatestShipDate: 'Latest Ship Date',
        colPortOfDischargeETA: 'POD ETA',
        colBookingStatus: 'Booking Status',
        colBookingStatusDate: 'Booking Status Date',
        colStatusHistoryDate: 'Status History Date',
        colBookingApprovalStatus: 'Booking Approval Status',
        colBookingApprovalStatusDate: 'Booking Approval Status Date',
        colCargoReadyDate: 'Cargo Ready Date',
        colContainerNo: 'Container #',
        colContainerSize: 'Container Size',
        colContainerKgs: 'Container G.W. (KG)',
        colContainerCBM: 'Container CBM',
        colPOLineNo: 'Line#',
        colTransportModeCode: 'Mode of Transportation',
        colFD: 'FD',
        colFDCountry: 'FD Country',
        colFDETA: 'FD ETA',
        colEmptyPickupDate: 'Empty Pickup Date',
        colCFSReceivedDate: 'CFS Received Date',
        colGateInPOLDate: 'Gate In (POL) Date',
        colLoadedPOLDate: 'Loaded (POL) Date',
        colDepartedPOLDate: 'Departed (POL) Date',
        colDischargedPODDate: 'Discharged (POD) Date',
        colGateOutPODDate: 'Gate Out (POD) Date',
        colArrivedPODDate: 'Arrived (POD) Date',
        colEmptyReturnedDate: 'Empty Returned Date',
        colUpdatedETAPODDate: 'Updated ETA (POD) Date',
        colUpdatedETAFDDate: 'Updated ETA (FD) Date',
        colShipToName: 'Ship To',
        colDepartment: 'Department',
        colBuyerReference: 'Buyer Reference',
        colSupplierReference: 'Supplier Reference',
        colOtherReference: 'Other Reference',
        colSupplierPONO: 'Supplier PO#',
        colBuyerContactEmail: 'Buyer Email',
        colFeederVessel: 'Feeder Vessel',
        colFeederVoyage: 'Feeder Voyage',
        colItemNo: 'Item #',
        colStyleDescription: 'Style Description',
        colItemQty: 'Item Qty',
        colOrderQty: 'Order Qty',
        colBalanceQty: 'Balance Qty',
        colGW: 'G.W. (KG)',
        colNAgentCode: 'Nomination Agent Code',
        colNotifyParty: 'Notify Party',
        colNotifyPartyMaster: 'Notify Party (Master)',
        colNotifyPartyCode: 'Notify Party Code',
        colHandlingOffice: 'Handling Office',
        colHandlingOfficeCode: 'Handling Office Code',
        colPld: 'PLD',
        colRAgentCode: 'Release Agent Code',
        colShipperCode: 'Shipper Code',
        colSalesmanCode: 'Salesman Code',
        colConsolidatable: 'Can be Consol',
        colConsolNo: 'Consol#',
        colCBM: 'CBM',
        colCartonDimemsion: 'Carton Dimension',
        colKG: 'KG',
        colPackageQty: 'Package Qty',
        colDangerousGoods: 'Dangerous Goods?',
        colPol: 'POL',
        colEtd: 'ETD',
        colPod: 'POD',
        colPodEta: 'POD ETA',
        colCustomer: 'Customer',
        colTradelane: 'Tradelane',
        colBookingCreatedBy: 'Booking Created By',
        colBookingUpdatedBy: 'Booking Updated By',
        colBookingLastUpdatedTime: 'Booking Last Updated Time',
        colBookingApprovalReason: 'Booking Approval Reason',
        colBookingApprovalRemarks: 'Booking Approval Remarks',
        colBookingApprovalUpdatedBy: 'Booking Approval Updated By',
        colProposedSailingOption: 'Proposed Sailing Option',
        colMotherVesselName: 'Vessel',
        colMotherVoyageCode: 'Voyage',
        colShipperReference: 'Shipper Reference',
        colConsigneeReference: 'Consignee Reference',
        colAgentReference: 'Agent Reference',
        colMRReference: 'M+R Reference',
        colLatestStatus: 'Latest Status',
        colStatusHistory: 'Status History',
        colCarrier: 'Carrier',
        colCarrierService: 'Carrier Service',
        colBookingApprovalUpdatedByProfile: 'Booking Approval Updated By Profile',
        colSpaceStatus: 'Space Status',
        colSailingDetails: 'Sailing Details',
        colSailingOptionNo: 'Sailing Option No.',
        colVessel: 'Vessel',
        colVoyage: 'Voyage',
        colContractType: 'Contract Type',
        colContractNo: 'Contract No.',
        colCarrierBookingStatus: 'Carrier Booking Status',
        colStatusDate: 'Status Date',
        colBookingCondition: 'Booking Condition',
        colUpdatedDatetime: 'Updated Datetime',
        colUpdatedBy: 'Updated By',
        miReactivateBooking: "Recover Booking",
        msgSureToReactivateBooking: "Are you sure you want to recover the booking?",
        miAcknowledgeCancelBooking: "Acknowledge Cancel",
        msgSureToAcknowledgeCancelBooking: "Are you sure you want to acknowledge the cancellation request?",
        miRejectCancelBooking: "Reject Cancel",
        msgSureToRejectCancelBooking: "Are you sure you want to reject the cancellation request?",
        miCancelBooking: "Cancel Booking",
        msgSureToCancelBooking: "Are you sure you want to cancel the booking?",
        miCancelSI: "Reverse House SI",
        msgSureToCancelSI: "Are you sure you want to Reverse House SI?",
        msgRemoveJobBeforeReverseSI: "Please remove Job/Order/Container in OPS before Reverse House SI",
        lblBookingApproval: "Booking Approval",
        lbHouseDoc: 'House Doc',
        msgSOAdviceError: 'Status \'Booking Advice\' is not applicable to the Booking as there is more than 1 proposed sailing. Please select "Approved" as the approval status and select one sailing option as the approved sailing.',
        colRemarks: "Remarks",
        colRemarkType: "Remark Type",
        colVisibleTo: "Visible To",
        colApprovalStatus: "Approval Status",
        lbApprovalStatus: "Approval Status",
        lbApprovalStatusTooltip: "Request For Approval: M+R OP use it to request for approval by agent/ consignee\nBooking Advice: No approval is required for the booking\nPending Customer's Approval: Customer use it to indicate that approval is pending\nApproved: Customer use it to indicate that approval is given\nRejected: Customer use it to indicate that the request for approval is rejected",
        lbNewRemarks: "New Remarks",
        lbVisibleTo: "Visible To",
        lbReasons: "Reasons",
        cbSendEmailNotifyTo: "Send Email Notification To",
        msgAddBookingApprovalSuccess: "Add Booking Approval Successfully",
        msgAddBookingApprovalFail: "Fail to add Booking Approval",
        lbSONo: "SO#",
        lbHBLNo: "HBL#",
        lbTrader: "Trader",
        lbNotifyParty: "Notify Party",
        lbNotifyPartyToolTip: "The Notify Party will receive copies of the shipping advice and/or arrivel notice from the receiving cargo agent at the destination. Other than for informational purpose, the Notify Party does not have any rights to the shipment.\n\nIf there is no Notify Party specified, it is customary that \"SAME AS CONSIGNEE\" will be shown on the BL rather than leaving it blank.",
        lbSameAsConsignee: "Same as Consignee",
        lbSameAsConsigneeToolTip: "The Notify Party will receive copies of the shipping advice and/or arrivel notice from the receiving cargo agent at the destination. Other than for informational purpose, the Notify Party does not have any rights to the shipment.\n\nIf there is no Notify Party specified, it is customary that \"SAME AS CONSIGNEE\" will be shown on the BL rather than leaving it blank.",
        msgToOrderWarning: "Warning: Having \"To Order [blank]\" as the consignee on the BL is highly not recommended given the inherent risk in the case of any loss of \"originals\" in the document delivery. Please consult the shipper to avoid having this instruction.",
        lbHandlingOffice: "Handling Office",
        msgHandlingOfficeRequired: "Please select a Handling Office",
        lbContact: "Contact",
        lbContactPhone: "Phone",
        lbPOR: "POR",
        lbPOL: "POL",
        lbPOD: "POD",
        lbPLD: "PLD",
        lbPOLEtd: "POL ETD",
        lbPLDEta: "PLD ETA",
        lbShipMode: 'Ship Mode',
        lbShipModeTooltip: 'The ship mode used from Port of Discharge (POD) to Place of Delivery (PLD)',
        lbFD: "FD",
        lbFDEta: "FD ETA",
        lbPOAllowableETDrange: 'PO Allowable ETD range',
        lbSpaceStatus: 'Space Status',
        msgSpaceStatusRequired: 'Please select Space Status',
        msgSpaceStatusSORelease: 'The Space Status must be Released SO in order to perform "House SI Submitted"',
        msgSelectCarrierBookingRequired: 'Please select a Carrier Booking',
        msgSelectNonTBCSailing: 'Please select a non-TBC Sailing when the Space Status is Released SO',
        msgSelectCarrierBooking: 'Please fill in the Selected Record when the Space Status is Released SO',
        msgCarrierSOReleaseDateRequired: 'Carrier SO Release Date required',
        msgRejectReasonRequired: 'Reject Reason required',
        msgCancelReasonRequired: 'Cancel Reason required',
        msgContractTypeRequired: 'Please select a contract type',
        lbSelectedSailingOption: 'Selected Sailing Option',
        msgSailingOptionRequired: 'Please select Sailing Option',
        lbSelectedRecord: 'Selected Record',
        lbCargoReadyDate: "Cargo Ready Date",
        lbContractType: "Contract Type",
        lbContractNo: "Contract No.",
        msgInvalidEmail: "Invalid email",
        msgEmptyRemark: "Please enter remark details or close the remark",
        msgFillRequire: "Please fill in the required column(s)",
        msgEmptyRemarkType: "Please enter New Remarks for {{i}} remark",
        msgUserContactEmailSuccess: "{{email}} is deleted successfully",
        msgUserContactEmailFail: "Fail to delete {{email}}",
        lbWarehouse: "Warehouse (LCL)",
        lbWarehouseSONo: "Warehouse SO No.",
        msgWarningEditBookingApproval: "Please save the existing booking or cancel edit before performing Booking Approval",
        msgWarningEditHouseDoc: "Please save the existing booking or cancel edit before performing House Doc",
        msgDeleteISFProfileSuccess: "Deleted successfully",
        msgDeleteISFProfileFail: "Delete fail",
        msgFillInRequiredTabFields: "Please revise highlighted tab(s)",
        msgConfirmAcceptChangeMsg: 'Accept changes will replace all containers, and all allocation between PO and containers will be deleted. Are you sure to continue?',
        colDefaultAgentCode: 'Code',
        colDefaultAgentName: 'Name',
        colDefaultAgentIsAutoFill: 'Default',
        colDefaultAgentDescription: 'Description',
        lblDefaultAgentConsigneeCode: 'Consignee Code',
        lblDefaultAgentCustomer: 'Customer',
        lblDefaultNominationAgentCode: 'Related Nomination Agent Codes',
        lblDefaultReleaseAgentCode: 'Related Release Agent Codes',
        polCfsClosingRequired: 'Please input CFS',
        polCyClosingRequired: 'Please input CY',
        polVgmClosingRequired: 'Please input VGM',
        polSiClosingRequired: 'Please input SI',
        msgSendEmailPopup: "Booking Number {{so_no}} will be saved. Do you want to send a BMS booking email notification to shipper, booking cc email and M+R OP?",
        msgSendCancelEmailPopup: "Booking Number(s) {{so_no}} have been confirmed before and will be cancelled. Do you want to send a BMS booking email notification to shipper, booking cc email and M+R OP?",
        lbConfirmSendEmail: "Yes & Save the Change",
        lbConfirmNotSendEmail: "No & Save the Change",
        lbConfirmSendCancelEmail: "Yes & Cancel the Bookings",
        lbConfirmNotSendCancelEmail: "No & Cancel the Bookings",
        lblSOStatus: "Status",
        pnlStatusHistory: "Status History",
        colSOStatus: "Status",
        colSOStatusAction: "Action",
        colSOStatusRemark: "Remark",
        colSOStatusUser: "User",
        colSOStatusUpdatedTime: "Updated Time",
        msgIncorrectExFactoryDate: "The Ex-Factory Date of Container# {{container_no}} must NOT be earlier than Cargo Ready Date ({{cargo_ready_date}})",
        msgMissingExFactoryDate: "Please enter the Ex-Factory Date for Container# {{container_no}}",
        msgMissingSealNO: "Please enter the Seal# for Container# {{container_no}}",
        msgMissingCarrierSONO: "Please enter the Carrier Booking# for Container# {{container_no}}",
        msgMissingPackageQTY: "Please enter the Package QTY for Container# {{container_no}}",
        msgExceedsGW: "Container #{{container_no}}: The difference between allocated GW ({{allocatedGW}}) and entered in container GW ({{containerGW}}) cannot exceeds 1",
        msgExceedsCBM: "Container #{{container_no}}: The difference between allocated CBM ({{allocatedCBM}}) and entered in container CBM ({{containerCBM}}) cannot exceeds 1",
        msgPOAlloExceedsQty: "PO#{{po_no}} Item#{{item_no}} {{field_name}} has {{allocated_qty}}{{unit}} in Container Details but only {{qty}}{{unit}} in PO Details.",
        msgResendEmailSuccess: "Resend S/O Sheet by email successfully",
        lbCopyToMenufacturer: "Copy to Manufacturer",
        lbCopyToContainerStuffingLocation: "Copy to Container Stuffing Location",
        lbCopyToShipTo: "Copy to Ship To",
        lbCopyFromConsignee: "Copy from Consignee",
        lbCopyToConsolidator: "Copy to Consolidator",
        lbShowFullHDOffice: "Show Full List Handling Office",
        msgLCLContainerAmountNotPass: "The 'Package Qty', 'G.W. (KG)' and 'CBM' of Container Line [#] should not be zero at the same time. If these 3 fields are zero, please delete this Container Line in order to save the change.",
        msgETDLaterThanCargoReadyDate: "ETD should be later than the Cargo Ready Date. Please check and amend the ETD and/ or the Cargo Ready Date",
        msgCYGateInDateEarlierThanPOL: 'CY Gate-in Date must be equal to/ eariler than ETD.',
        msgCFSRecvDateEarlierThanPOL: 'CFS Recevied Date must be equal to/ eariler than ETD.',
        msgCYGateInDateEarlierThanCFSRecvDate: 'CFS Recevied Date must be equal to/ eariler than CY Gate-in Date (within the same container).',
        msgETDMoreThan14Days: "ETD is more than 14 days after Cargo Ready Date. Please check the ETD and the Cargo Ready Date. Click \"OK\" to continue",
        msgContactPersonRequired: "Please input the Contact Person",
        msgContactEmailRequired: "Please input Contact Email",
        msgNotifyPartyRequired: "Please select a Notify Party",
        msgNotifyPartyAddressRequired: "Please input Notify Party Address",
        msgNotifyPartyCityRequired: "Please input Notify Party City",
        msgPORRequired: "Please select a Port of Receipt at origin",
        msgPORHBLRequired: "Please enter Port of Receipt at origin in HBL",
        msgTotalPkgQtyRequired: "Please enter Total Package Qty",
        msgPkgUnitRequired: "Please enter Package Qty Unit",
        msgTotalCBMRequired: "Please enter Total CBM",
        msgTotalKGRequired: "Please enter Total KG",
        msgMarksAndNoRequired: "Please enter Marks & No",
        msgGoodsDescRequired: "Please enter Goods Description",
        msgCommodityRequired: "Please select a Commodity",
        msgDangerousGoodsRequired: "Please choose if it contains Dangerous Goods",
        msgUNNoRequired: "Please input UN Number",
        msgOtherCommodityRequired: 'Please specify Other Commodity',
        msgCargoReadyDateRequired: "Please select the Cargo Ready Date",
        msgIncotermsRequired: "Please select Incoterms",
        msgIncotermsLocaionRequired: "Please enter Incoterms Location",
        msgIncotermsLocaionNotAlign: 'The Incoterms Location does not seem to be aligned with the routing locations.',
        msgFreightRequired: "Please choose Collect or Prepaid",
        msgPrepaidUptoRequired: "Please enter Prepaid Upto",
        lbNoOfOriginal: 'No. of Original',
        lbTransportDoc: 'Transport Document',
        lbTransportDocTooltip: 'When the Main House Doc is assigned in the "House Doc" workspace, this field refers to the Main House Doc\'s type.\nTo update the Transport Document, please update the Main House Doc\'s type.',
        msgTransDocRequired: "Please select a Transport Document",
        msgNoOfOriginalRequired: "Please input the No of Original",
        msgHBLOfficeRequired: "Please select a HBL Issued Office",
        lblConsolidatable: "Can be Consol",
        msgConsolidatableRequired: "Please select whether this shipment can be consol",
        lblConsolidatableTooltip: "Yes - plan to build a consol\nNo - will not be consol",
        lbAllowSwitchHouseDoc: "Allow Switch House Doc",
        msgNotAllowLCLContainer: "Please remove Container Size 'LCL' from Container Cargo section in the Details tab because the Service is not LCL",
        msgBookingActionSuccess: "Booking Number {{sono}} is {{action}} successfully. {{send_email}}",
        msgHBLDrafted: 'HBL drafted',
        msgHBLPrinted: 'HBL printed',
        msgSendEmailSuccess: "Email notification has been sent to shipper, booking cc email address and OP successfully.",
        autoUpdateTooltip: "To auto recalculate and update the Cut-off dates (For Shipper) when there is any change to the Cut-off dates (From Carrier) to this sailing.",
        msgTransDocNoOfOriginalNotMatch: "Transport Document and No. of Original not match. Please edit either one field.",
        autoUpdatePLDFDTooltip: "To auto recalculate and update PLD ETA and FD ETA when there is any change in POD ETA of this sailing.",
        lbToOrder: "To Order Of",
        lbToOrderToolTip: "If the BL is consigned \"TO ORDER OF [consignee name]\", the consignee can endorse the BL and transfer the rights to the goods represented by the BL to someone else, effectively showing a new consignee. This is typically used if a shipment is covered by a Letter of Credit which requires the BL to be issued \"TO ORDER OF [BANK XYZ]\".\n\nHaving “To Order [blank]” as the consignee on the BL is highly not recommended given the inherent risk in the case of any loss of “originals” in the document delivery.",
        msgMissingTypeOfEntry: 'Please select a {{type}} Type Of Entry',
        msgSelectSailingSchedule: 'Please select from "Proposed Sailing for Booking"',
        msgSelectRate: 'Please select a rate to approve. If there is any question, please contact BMS support.',
        msgMissingLCLContainer: "There should be at least 1 LCL Container Size for LCL service",
        msgMissingField: "Please enter the following field(s)",
        msgPLDETARequired: "Please select the PLD ETA",
        msgInvalidPLDETA: 'PLD ETA should be later than or equal to POD ETA, and earlier than or equal to FD ETA',
        msgInvalidRateContainerHeader: 'The container size of the selected rate option is different with the booking details. Please revise the container size in booking details or select another rate for this container size.',
        msgInvalidRateContainerContent: '{{type}}: {{container_sizes_content}}',
        msgInvalidRateContainerContent2: 'Rate {{rate_container_sizes_content}}',
        msgInvalidRate: 'Invalid rate in selected sailing option. Please refresh or remove the rate and try again.',
        msgMissingRateContainerSelect: 'Please add rates for at least one container size.',
        msgFDETARequired: "Please select the FD ETA",
        msgInvalidFDETA: 'FD ETA should be later than or equal to both POD ETA and PLD ETA',
        msgMobileSiteNotFound: "Please set the Mobile Site in configuration",
        msgInvalidUser: "Invalid User. Please login again and try again.",
        msgAddTokenFail: "Add Token failed. Please try again.",
        viewShipmentStatus: "Click to view shipment status.",
        viewSailingScheduleDetails: "Click to view sailing schedule details.",
        msgSONotExistsInMobile: "Shipment status not yet available",
        lbCopyFromConsignee: "Copy from Consignee",
        lbIncludeFullHistory: "Include Full History",
        lbIncludeVessel: "Include Vessel",
        lbIncludePricing: "Include Pricing",
        lbIncludeRates: "Include Rates",
        lbSendEmailToTooltip: "By default, the system automatically sends a daily summary of all relevant communications to the recipient based on the recipient's own preference. By clicking this option, the system will send an additional instant email to specially alert the recipient of this communication. Please only select this option if special attention is needed!",
        lbIncludeFullHistoryTooltip: "Check to include the full approval communication history in this instant email notification. Please also check and select the correct Visible To.",
        lbIncludePricingTooltip: "Check to include \"Pricing\" remarks in this instant email notification. Please take extra precaution when selecting this option to avoid disclosing confidential information undesirably!",
        lbVisibleToConfigTooltip: 'Check to include the remarks to be included in this instant email notification. Please take extra precaution when selecting this option to avoid disclosing confidential information undesirably.',
        lbIncludeVesselTooltip: "Check to include the vessel in this instant email notification. Vessel is excluded if \"Include Vessel\" is not checked.",
        lbIncludeRatesTooltip: "Check to include total rate per container in this instant email notification. Please take extra precaution when selecting this option to avoid disclosing confidential information undesirably!",
        msgDelContainerInSI: "Please delete container in Shipment Instruction.",
        smartSearchTooltip: "Enter SO#, PO#, Consignee or HBL# to search. Click the Customized Filter for advance searching.\n\nOnce you entered the keyword in this field, you could click 'Tab' and then press 'Enter' to perform searching.",
        lbTEU: "TEU",
        lbSailingSelectedInBookings: "Sailing selected in bookings",
        lbSailingSelectedInBookingsHover: 'Turn on this option to limit the search only for sailings which are used in other booking.',        
        msgRestorePrevSearchCriteria: "The previous search criteria has been restored.",
        msgEmptyPartyCode: "{{party_type}} code is empty, please enter {{party_type}} code before confirming booking or submitting SI",
        msgPartyStatusNotActive: "{{party_type}} status is not active, please active {{party_type}} status before confirming booking or submitting SI",
        msgWarnTBCContainers: "{{container_no}} temporary Container#, please update the Container# in BMS when available.",
        lbToBeTelexRelease: "To-be telex release",
        lbMasterFreight: "Master Freight",
        lbMasterFreightTooltip: "Carrier / Co-loader Freight",
        msgMasterFreightRequired: "Please select a master freight",
        lbMasterFreightPlaceholder: "Select Master Freight",
        lbCarrierMBLNo: "Carrier MBL No.",
        lbCarrierMBLNoPlaceholder: "Enter Carrier MBL No.",
        msgCarrierMBLNoRequired: "Please enter Carrier MBL No.",
        lbOceanFreightRemarks: 'Ocean Freight Remarks',
        lbColoaderBLNo: "Co-loader BL No.",
        lbColoaderBLNoPlaceholder: "Enter Co-loader BL No.",
        msgMissingPONO: "Please enter PO#",
        lbSearchSailing: 'Search Sailing',
        lbCreateSailing: 'Create Manual Sailing',
        lbExternalContractType: 'External Contract Type',
        lbExternalContractNo: 'External Contract No',
        lbInternalContractType: 'Internal Contract Type',
        lbInternalContractNo: 'Internal Contract No',
        lbInternalContract: 'Internal Contract',
        lbInternalContractTooltip: 'Switch it on when our internal contract type that we sign with carrier is not the same as the external contract type that the we informed agent/agent informed consignee. The "Internal Contract Type/ No." are visible to M+R internal users only',
        msgWarningHBLNameNotAlign: 'Name in HBL ({{hbl_name}}) doesn\'t align with the selected {{party_type}} code. Please make sure to select a correct {{party_type}} code.',
        lbDisposalOfCns: 'at the disposal of the consignee',
        lbForwardToCns: 'to be forwarded to the consignee',
        lbPrecarriageBy: 'Precarriage by',
        lbOceanVessel: 'Ocean Vessel',
        lbPlaceOfReceipt: 'Place of Receipt',
        lbPortOfLoading: 'Port of Loading',
        lbPortOfDischarge: 'Port of Discharge',
        lbPlaceOfDelivery: 'Place of Delivery',
        lbFreightPayableAt: 'Freight payable at',
        lbNoOfOriginalBL: 'No. of original Bs/L',
        lbMarksAndNo: 'Marks and nos.',
        lbNoAndKindOfPackages: 'No.and kind of packages',
        lbGoodsDescription: 'Description of goods',
        lbVolume: 'Volume / Measurements',
        lbUpto: 'UP TO',
        lbShippedOnBoard: 'SHIPPED ON BOARD',
        lbCargoReceived: 'CARGO RECEIVED',
        lbIntendedVesselRouting: 'INTENDED VESSEL & ROUTING',
        msgWarningRemarkExceedMaxImageSize: 'Image size cannot exceed {{maxImageSize}} MB.',
        msgWarningRemarkExceedMaxHTMLTextSize: 'Total size cannot exceed {{maxHTMLTextSize}} MB.',
        msgFinalWarningRemarkExceedMaxHTMLTextSize: 'Total Remark size (current: {{currentHTMLTextSize}}MB) cannot exceed {{maxHTMLTextSize}} MB. Please remove image(s).',
        msgRemarkExceedMaxTextLength: 'Total Remark length (current: {{currentTextLength}} characters) cannot exceed {{maxTextLength}} characters.',
        phRemarkbraftEditor: 'Please type your remarks or paste your screenshot here.',
        msgMissingENSBuyerName: 'Missing ENS Buyer Name',
        msgMissingENSBuyerAddress1: 'Missing ENS Buyer Address 1',
        msgMissingENSBuyerCity: 'Missing ENS Buyer City',
        msgMissingENSBuyerCountry: 'Missing ENS Buyer Country / Region',
        msgMissingENSBuyerPhone: 'Missing ENS Buyer Phone',
        msgMissingENSBuyerEmail: 'Missing ENS Buyer E-mail',
        msgMissingENSBuyerEORINo: 'Missing ENS Buyer EORI No',
        msgMissingENSSellerName: 'Missing ENS Seller Name',
        msgMissingENSSellerAddress1: 'Missing ENS Seller Address 1',
        msgMissingENSSellerCity: 'Missing ENS Seller City',
        msgMissingENSSellerCountry: 'Missing ENS Seller Country / Region',
        msgMissingENSSellerPhone: 'Missing ENS Seller Phone',
        msgMissingENSSellerEmail: 'Missing ENS Seller E-mail',
        msgMissingENSSellerEORINo: 'Missing ENS Seller EORI No',
    },
    lbSOColorDesc: {
        newEBookgingRed: "Pending for confirmation more than 18 hours",
        newEBookgingYellow: "Pending for confirmation more than 8 hours",
        newEBookgingGreen: "Pending for confirmation",
        amendEBookgingRed: "Pending for confirmation more than 18 hours",
        amendEBookgingYellow: "Pending for confirmation more than 8 hours",
        amendEBookgingGreen: "Pending for confirmation",
        cancelEBookgingRed: "Pending for acknowledgement more than 18 hours",
        cancelEBookgingYellow: "Pending for acknowledgement more than 8 hours",
        cancelEBookgingGreen: "Pending for acknowledgement",
        newManualBookgingRed: "Pending for acknowledgement more than 18 hours",
        newManualBookgingYellow: "Pending for acknowledgement more than 8 hours",
        newManualBookgingGreen: "Pending for acknowledgement",
        confirmBookingRed: "Confirmed Booking pending for booking approval for more than 2 days",
        confirmBookingYellow: "Confirmed Booking pending for booking approval for more than 1 day",
        confirmBookingGreen: "Confirmed Booking pending for booking approval",
        pendingSIRed: "Pending for submit SI more than 7 days",
        pendingSIYellow: "Pending for submit SI more than 5 days",
        pendingSIGreen: "Pending for submit SI",
        requestForApprovalRed: "Pending on requesting for approval more than 2 days",
        requestForApprovalYellow: "Pending on requesting for approval more than 1 day",
        requestForApprovalGreen: "Pending on requesting for approval",
        pendingForCustomerApprovalRed: "Pending on getting customer approval more than 2 days",
        pendingForCustomerApprovalYellow: "Pending on getting customer approval more than 1 days",
        pendingForCustomerApprovalGreen: "Pending on getting customer approval",
        bookingRejectedRed: "Booking was rejected more than 1 day",
        bookingRejectedYellow: "Booking was rejected",
        bookingApprovedGreen: "Booking was approved",
    },
    lbConsolColorDesc: {
        newConsolidationRed: "Pending for confirmation more than 18 hours",
        newConsolidationYellow: "Pending for confirmation more than 8 hours",
        newConsolidationGreen: "Pending for confirmation",
        amendConsolidationRed: "Pending for confirmation more than 18 hours",
        amendConsolidationYellow: "Pending for confirmation more than 8 hours",
        amendConsolidationGreen: "Pending for confirmation",
        cancelConsolidationRed: "Pending for acknowledgement more than 18 hours",
        cancelConsolidationYellow: "Pending for acknowledgement more than 8 hours",
        cancelConsolidationGreen: "Pending for acknowledgement",
        newManualConsolidationRed: "Pending for acknowledgement more than 18 hours",
        newManualConsolidationYellow: "Pending for acknowledgement more than 8 hours",
        newManualConsolidationGreen: "Pending for acknowledgement",
        confirmConsolidationRed: "Confirmed consolidation pending for consol approval for more than 2 days",
        confirmConsolidationYellow: "Confirmed consolidation pending for consol approval for more than 1 day",
        confirmConsolidationGreen: "Confirmed consolidation pending for consol approval",
        requestForApprovalRed: "Pending on requesting for approval more than 2 days",
        requestForApprovalYellow: "Pending on requesting for approval more than 1 day",
        requestForApprovalGreen: "Pending on requesting for approval",
        pendingForCustomerApprovalRed: "Pending on getting customer approval more than 2 days",
        pendingForCustomerApprovalYellow: "Pending on getting customer approval more than 1 days",
        pendingForCustomerApprovalGreen: "Pending on getting customer approval",
        consolidationRejectedRed: "Consolidation was rejected more than 1 day",
        consolidationRejectedYellow: "Consolidation was rejected",
        amendedBookingPendingRed: "Amended booking pending for more than 2 days",
        amendedBookingPendingYellow: "Amended booking pending for more than 1 days",
        amendedBookingPendingGreen: "Amended booking pending",
        loadPlanPendingAllocationRed: "Pending for allocation less than 3 days before ETD",
        loadPlanPendingAllocationYellow: "Pending for allocation less than 7 days before ETD",
        loadPlanPendingAllocationGreen: "Pending for allocation",
        consolidationApprovedGreen: "Consolidation was approved"
    },
    lbParty: "Party",
    lbPartyID: "Party ID",
    lbPartyCode: "Party Code",
    lbOtherCode: "Other Code",
    lbPartyType: "Party Type",
    lbPartyTypeRequired: "Please select a Party Type",
    lbPartyRequired: "Please enter a Party",
    lbContactPerson: "Contact Person",
    lbCity: "City",
    lbState: "State",
    lbShortName: "Short Name",
    lbFullName: "Full Name",
    lbStatus: "Status",
    msgStatusRequired: "Please select a Status",
    msgCostCenterCodeRequired: "Please enter Cost Center Code",
    msgUpdatePartySuccess: "Updated party successfully",
    msgUpdatePartyFail: "Fail to update party",
    lbTradeLaneID: "TradeLane ID",
    lbTradeLaneName: "TradeLane Name",
    lbTradeLaneType: "TradeLane Type",
    lbCountryUnlocode: "POD Country/POL Country",
    lbOfficeCode: "Office Code",
    lbAccountCode: "Account Code",
    lbEffectiveDate: "Effective Date",
    lbTradeLane: "TradeLane",
    lbCustomerTypeID: "Customer Type ID",
    lbCustomerCode: "Customer Code",
    warehouse: {
        lblWarehouseCode: "Warehouse Code",
        msgWarehouseRequired: "Please select a Warehouse",
        lblFullName: "Name",
        lblOffice: "Office",
        lblAddress1En: "English Address 1",
        lblAddress2En: "English Address 2",
        lblAddress3En: "English Address 3",
        lblAddress4En: "English Address 4",
        lblFullNameLocal: "Local Name",
        lblAddress1Local: "Local Address 1",
        lblAddress2Local: "Local Address 2",
        lblAddress3Local: "Local Address 3",
        lblAddress4Local: "Local Address 4",
        lblTel: "Tel",
        lblContactPerson: "Contact Person",
        lblContactEmail: "Contact Email",
        lblRemark: "Remarks",
        lblAttachment: "Attachment",
        lblStatus: "Status",
        msgFullNameRequired: "Please input name",
        msgAddress1Required: "Please input address 1",
        msgAddress2Required: "Please input address 2",
        msgDuplicate: "Warehouse already exists"
    },
    accountGroupCode: {
        msgDuplicate: "Account Group Code already exists"
    },
    sysNotice: {
        lbNoticeType: "Notice Type",
        lbNoticeTitle: "Notice Title",
        lbNoticeBody: "Notice Body",
        lbMod: "Affected System",
        lbValidDate: "Valid Date",
        lbRemarks: "Remarks",
        lbCreatedBy: "Created By",
        lbCreatedDate: "Create Date",
        lbUpdatedBy: "Updated By",
        lbUpdatedDate: "Updated Date",
        msgUpdatedSysNoticeSuccessfully: "Updated System Notice Successfully",
        msgUpdateSysNoticeFail: "Fail to update System Notice",
        msgConfirmDelete: "Are you sure to delete this system notice?",
        lbDelete: "Delete",
        lbCopy: "Copy"
    },
    notificationCenter: {
        colModule: "Module",
        colNotificationName: "Notification",
        colDescription: "Description",
        colCreationParty: "Creation Party",
        colSubject: "Subject",
        colParameter: "Parameter",
        colSendTo: "Send To",
        colModuleId: "Schedule ID",
        colRecurrence: "Recurrence",
        colStartDate: "Start Date",
        colEndTime: "End Time",
        colCc: "Cc",
        lbNotification: "Notification",
        lbModule: "Module",
        msgModuleRequired: "Please enter Module",
        msgNameRequired: "Please select a Name",
        msgSendAtRequired: "Please enter Send At",
        msgRecurrenceRequired: "Please select a Recurrence",
        msgTimeRangeRequired: "Please enter Time Range",
        msgSubjectRequired: "Please enter Subject",
        lbNotificationName: "Notification",
        lbDescription: "Description",
        lbNotificationSetting: "Notification Setting",
        lbOwnershipSetting: "Ownership Setting",
        lbParameter: "Parameter",
        lbDistributionSetting: "Distribution Setting",
        lbNotificationSubject: "Notification Subject",
        lbTo: "To",
        msgToRequired: "Please enter To",
        lbCc: "Cc",
        lbBcc: "Bcc",
        lbStartDate: "Start Date",
        msgStartDateRequired: "Please enter Start Date",
        lbEndDate: "End Date",
        lbRecurrence: "Recurrence",
        lbRecurrenceToolTip: "Daily: the notification will be sent out daily\nWeekly: the notification will be sent out on the selected weekday.For example, the notification will be sent out every Monday when the notification is set to send out on Monday on a weekly basis",
        DaysOfWeek: "Days of week",
        lbSendTime: "Send Time",
        lbCustimizedSetting: "Notification Customized Setting",
        lbRemarkType: "Remark Type",
        lbVisibleTo: "Visible To",
        lbTimeRange: "Time Range",
        lbIncludeRates: "Include Rates In Email",
        msgDeleteSuccess: 'Deleted Successfully',
        msgGetError: "Error occured, please refresh the page.",
        msgRecvTypeRequired: "Please select a Recvive Type",
        msgPartyTypeRequired: "Please select a Party Type",
        msgPartyIDRequired: "Please select a Party",
        msgRoleRequired: "Please select a Role"
    },
    routingTemplate: {
        msgDeleteSuccess: 'Deleted Successfully',
        lbRoutingTemplateName: 'Routing Template Name',
        msgRoutingTemplateNameRequired: 'Please enter Routing Template Name',
        lbCustomer: 'Customer',
        lbConsignee: 'Consignee',
        lbPOD: "Port of Discharge (POD)",
        lbEffectiveFrom: "Effective From",
        lbEffectiveTo: "Effective To",
        lbCanBeOverWritten: "Can Be Overwritten",
        msgConsigneeRequired: "Please enter Consignee",
        msgPODPLDFDRequire: "Please enter either POD, PLD or FD",
        msgUpdateRoutingTemplateSuccess: "Updated Routing Template successfully",
        msgCreateRoutingTemplateSuccess: "Create Routing Template successfully",
        lbRemarks: "Remarks",
        lbRoutingTemplateNameTip: "Please name the routing template in simple words and understandable to OP",
        lbCustomerTip: "Enter a Customer (Key Account) of the routing template for the purpose of easier management on the BMS-Routing Tempalte Maintenance screen",
        autoUpdatePLDTooltip: "Switch on this button if there is a validation on the day difference between POD ETA and PLD ETA could be defined",
        autoUpdateFDTooltip: "Switch on this button if there is a validation on the day difference between POD ETA and FD ETA could be defined",
        lbPLDETAValidation: "PLD ETA Validation",
        lbFDETAValidation: "FD ETA Validation",
        msgEffectiveFromRequired: "Please enter Effective From",
        lbEffectiveFromTip: "Define an effective date of this routing template",
        lbEffectiveToTip: "Leave the effective to as empty if there is no end date",
        lbCanBeOverWrittenTip: "Switch on this button if OP is allowed to override the default values (e.g.: defined POD/ PLD/ FD) and PLD/ FD ETA validations"
    },
    msgUpdateTradeLaneSuccess: "Updated TradeLane successfully",
    msgUpdateTradeLaneFail: "Fail to update TradeLane",
    msgConfirmDeleteTradeLane: "Are you sure want to delete TradeLane [{{tradelane_name}}]?",
    msgDeleteTradeLaneSuccess: "Deleted TradeLane successfully",
    msgDeleteTradeLaneFail: "Fail to delete TradeLane",
    lbTradelaneCountryRequired: "Please select a Country",
    lbTradelaneUnlocodeRequired: "Please select a POL",
    lbTradelanePOLCountryRequired: "Please select a POL Country",
    msgUpdateSettingSuccess: "Updated Setting successfully",
    msgCreateSettingSuccess: "Create Setting successfully",

    lbPartyGroup: "Party Group",
    lbPartyGroupID: "Party Group ID",
    lbGroupName: "Group Name",
    lbGroupType: "Group Type",
    lbSubGroup: "Sub Group",
    lbPartyGroupParty: "Party",
    msgUpdatePartyGroupSuccess: "Update party group successfully",
    msgUpdatePartyGroupFail: "Fail to update party group",
    msgPartyGroupMissingField: "Please fill in the missing fields",
    msgPartyGroupDuplicate: "Group Name must be unique.",
    msgPartyGroupPartyAlreadyAssigned: "Consignee Code: {party_codes} already exist in Key Account: {existing_party_group_name}.",

    colCreatedBy: "Created By",
    colCreatedDate: "Created Date",
    colUpdatedBy: "Updated By",
    colUpdatedDate: "Updated Date",

    /* Web Invoice Label*/
    lbNotSearchedYet: 'Please either apply a \'Customized Filter\' or click \'Search\' to perform searching',

    lbUpdatedBy: "Updated By",
    lbUpdatedDtTm: "Updated Date / Time",
    lbWithoutSave: "Are you sure to quit without save?",
    lbInvModal_APRef: {
        lbTitle: "Batch update AP Ref.",
        lbAPRef: "AP Ref.",
        lbWay1: "Way 1 - Please enter the AP Ref. in the following textbox. The entered AP Ref. will be applied to all selected invoices (listed above) after clicking the 'OK' button.",
        lbWay2: "Way 2 - Upload an excel file for batch updating the AP Ref. The system will capture the Invoice# and AP Ref. from the excel file, and then display the change preview below. Click the 'OK' button to apply the change.",
        lbFile_Tooltip: "The system will read the excel file to capture the Invoice# and AP Ref. for batch updating the AP Ref.. Please make sure the file contains the word of Invoice# and AP Ref. on the first row in two different columns",
    },
    lbInvModal_SubTypeTaxRate: {
        lbTitle: "Update 'Invoice Sub - type' and 'Tax Rate Code'",
        lbInvoiceSubtype: "Invoice Sub-type",
        lbTaxRate: "Tax Rate Code",
    },
    lbInvModal_UploadDoc: {
        
        lbDocTypeContent_SupportingDoc: 'Supporting Document',
        lbDocTypeContent_IncomingInvoice: 'Incoming Invoice',
        lbDocTypeContent_Statement: 'Statement(结算单)',
        lbDocTypeContent_FpFzInvoice: 'Fully Digitized E-Invoice (全电发票)',
        lbTitle: "Batch Upload Document",
        lbDocument: "File",
        lbRemark: "Remarks",

        lbDocTypeDesc_FpFzInvoice:'Web Invoice will read and capture the AP Ref. from this uploaded document.\nYou will be notified once completed. ',
    },
    lbInvModal_CRSummary: {
        lbTitle: "Print CR summary",
        lbSearchPlaceHolder: "Fill in the Invoice#",
    },
    lbInvModal_StatusUpdate: {
        msgSureToChangeState: "Are you sure the following records change status to @action?",
        lbRemark: "Remarks",
        lbPrintingFormat: "Print Format",
        lbWay1: "Way 1 - All selected invoices (listed above) will be posted after clicking the 'OK' button.",
        lbWay2: "Way 2 - Upload an excel file for batch posting. The system will capture the Invoice# from the excel file, and then display the Invoice# below for preview. Click the 'OK' button to post the invoices (mentioned on the excel file).",
        lbFile_Tooltip: "The system will read the excel file to capture the Invoice# for batch posting. Please make sure the file contains the word of Invoice# on the first row.",
    },
    lbInvModal_PrintOption: {
        lbTitle: "Print Options",
        lbDescription: "This setting will be reset after refresh. ",
        lbSea: "Sea",
        lbAir: "Air",
    },
    lbInvModal_Email: {
        CONFIRMATION_DOCUMENT: 'Confirmation',
        REAL_DOCUMENT: 'Real'
    },
    lbInvModal_GroupPanel: {
        lbSearchPlaceHolder: "Fill in the Group#",
    },
    lbInvModal_QuickCreate: {
        lbTitle_BlankInv: "Quick Create Invoice",
        lbTitle_ReferenceInv: "Copy Invoice To",
        lbSearchPlaceHolder: "Fill in the Job#",

        lbPasteSearchCurrentJob: "Paste and search Job# from the selected invoice",


        lbRefInvoice_Tooltips: "Fill in an existing invoice number in order to copy its header information and charge information to the newly created invoice.",
        lbRefInvoice: "Reference Invoice#:",

        lbErrorMessageTitle: "Unable to create/ copy invoice",

        //lbDescription: "This setting will be reset after refresh. ",
        //lbSea: "Sea",
        //lbAir: "Air",
    },
    lbInvModal_PrintHistory: {
        lbTitle: "Merge Printouts",
        lbType: "Document Type",
        lbAutoMerge: "Auto Merge PDF",
        lbNextNumber: "Next Print#",

        lbDocType999: 'Invoice',
        lbDocType9: '7026 Tax Invoice',
        lbDoc_Type999_PrintParams1_Label: 'Job#',
        lbDoc_Type9_PrintParams1_Label: 'Invoice# / Government Invoice#',

        lbJob_Tooltip: 'To search for multiple Job#, please enter multiple Job# separated by comma (,) e.g. (0001,0002).',
    },
    lbInvErr: {
        lbNoPermission: "You have no permission",
        lbPleaseSelect: "Please Select",
        lbPleaseInput: "Please Input",
        //lbNoZero: "Cannot be 0",
        //lbMaxValueErr: "Please input value less than (maxNum)",
        //lbMinValueErr: "Please input value greater than (minNum)",
        lbOutOfRangeErr: "Please input value between (minNum) and (maxNum) (default: (default_value))",
        lbJobNoNotFound: "This job no. cannot be found.",

        lbMissingExchangeRate: "Cannot found exchange rate for ledger (ledger). ",

        /*API Error*/
        lbJobDataNotExist: "This Job No. cannot get enough data.",
        lbAccountCodeNotFound: "'Account Code' and/ or 'Job Office' cannot get from the selected Job. ", //--Please select it manually.

        /*Invoice */
        lbMissingAPReference: 'Please fill in AP Ref. before posting.',
        lbMissingAPReference2: 'Please fill in AP Ref.', //Batch update AP Ref Modal
        lbMissingJobNo: 'Please input at least 1 Job#.',
        lbMissingHBLNoInJob: 'Missing HBL#. Please ensure HBL# is entered in the Job#(jobNo).',
        lbUnexpectedJob: 'Unexpected Job#(jobNo) content mixed in this invoice.',
        lbMissingIssueForm: 'Please input Issue Party.',
        lbMissingSO: 'Please input at least 1 SO#.',
        lbMissingCurrency: 'Please input invoice currency. ',
        lbCannotUseTempCode: 'Temp Consigee Code should not be used. Please update Consignee Code.',
        lbDNResultInDR: 'DN & IDN should have DR result. ',
        lbCNResultInCR: 'CN & ICN should have CR result. ',
        lbMissPLRecord: 'Please input at least 1 P&L Charge Item.',
        lbMissGCRecord: 'Please input at least 1 General Charge Item.',
        lbMissCRRecord: 'Please input at least 1 Cheque Request Item.',
        lbMissingGovInvoiceNo: 'Please fill in Government Invoice#.',
        lbMissingInvoiceDate: 'Please fill in Invoice Date.',

        lbMissingHeader: 'Please fill in all the required fields in Header.', //Server side check (low error rate)
        lbMissingPLDetails: 'Please fill in all the required fields in P & L Charge Details.',
        lbMissingGCDetails: 'Please fill in all the required fields in General Charge Details.',
        lbMissingCRDetails: 'Please fill in all the required fields in Cheque Request Details.',

        lbCheckPLDetails: 'Please check and resave P & L Charge. ',
        lbCheckGCDetails: 'Please check and resave General Charge. ',
        lbCheckCRDetails: 'Please check and resave Cheque Request. ',

        lbMissingDocUpload: 'Please select / drag a file for Document Upload.',
        lbARChargeCodeError: 'Please ensure Charge Code & DR/CR are selected correctly in General Charge tab. AR charge code should have DR result while AP charge code should have CR result.',
        lbAPChargeCodeError: 'Please ensure Charge Code & DR/CR are selected correctly in General Charge tab. AR charge code should have CR result while AP charge code should have DR result.',
        lbMixedJob_SeaAir: 'The mode of transportation should be the same among all selected jobs.',
        lbMixedJob_HdOff: 'The job office should be the same among all selected jobs.',
        lbMixedJob_JobPeriod: 'The job period should be the same among all selected jobs.',
        lbMixedJob_JobType: 'The inbound/outbound should be the same among all selected jobs.',
        lbWaitForContainerDetailsLoad: 'Job\'s container information is being downloaded to Web Invoice. Please click the \'Save\' button after few seconds.',
        lbLogisticJobNoPL: 'Logistic Job cannot create Profit Share Invoice',

        //Mismatch Charge Item
        lbMismatchGCItem: 'Total General Charge Amount does not match General Charge Details Amount.',
        lbMismatchPLItem: 'Total PL Amount doest not match PL Details Amount.',
        lbMismatchCRItem: 'Total CR Amount doest not match CR Details Amount.',

        lbJobNoEditLock: 'Job# (jobNo) cannot be changed.',

        /*Cross check error*/
        lbJobRelatedSO: 'Job No. (jobNo): Related SO No [(soNo)] exists. Please remove the SO first before changing the Job#. ',

        lbJobRelatedPLHBL: 'Job No. (jobNo): Related PL HBL Line[Line (lineNo)] exists. ',
        lbJobRelatedPLHBL_reverse: 'Please remove Job No. (jobNo) relationship in this row. ',

        lbPostChectError: 'Cannot post the record(s) due to missing data.',

        //GC
        lbMissingJobData: 'Job data is missing.',

        //Not in selection list
        lbNotInSelectionList: 'The selected value is invalid and not exist in the selection list. Please select the value from the selection list.',


        //Template library
        lbAdoptTmplib_GC: '"(template_name)" is applied. Charge Item#(fromRow) - (toRow) are added into the General Charges Details table.',
        lbAdoptTmplib_GC_Nothing: '"(template_name)" is applied. Nothing is added into the General Charges Details table.',
        lbUndoTmplib_GC: '(counter) Charge Items are removed from the General Charges Details table.',
        lbAdoptTmplib_CR: '"(template_name)" is applied. Charge Item#(fromRow) - (toRow) are added into the Cheque Request Details table.',
        lbAdoptTmplib_CR_Nothing: '"(template_name)" is applied. Nothing is added into the Cheque Request Details table.',
        lbUndoTmplib_CR: '(counter) Charge Items are removed from the Cheque Request Details table.',

        //Status
        lbStatusUpdateTimeLag: 'Status cannot updated. Please try again.',
        lbStatusUpdateAccountLock: 'This account is locked(Closing process is running). Please try again after fews minutes.',
        lbAccountCodeNotForPost: "Account code[(account_code)] is not supported for posting. Invoice ID[(invoice_id)] cannot post. Please contact administrator.",

        //Success Message
        lbSaveSuccess: 'Invoice saved successfully.',
        lbSaveError: 'Change hasn\'t been saved because system found this invoice is being concurrently updated by other user/ session. Please try again.',
        lbStatusSuccess: 'Status updated successfully.',

        //SOPMS error
        lbSOPMS_ChargeCodeNotFound: "Ruleset (template_section_id) Setting charge code has conflict. ",
        lbSOPMS_MinChargeConflict: "Ruleset (template_section_id) Setting Min charge code has conflict. ",
        lbSOPMS_MaxChargeConflict: "Ruleset (template_section_id) Setting Max charge code has conflict. ",

        ActionItem_RedoGCChargeCode: "Cannot found valid charge code information.",

        //Export Excel
        lbExportExcelOver: "Cannot export all records, you can export a maximum of 10,000 records at a time. Please narrow down your search.",

        //Exchange Rate Bug Warning
        lbSameCurrencyExchangeRate1: "Exchange Rate in General Charge should be 1 if the Currency in General Charge record is the same as Invoice Currency in Header.",

        //Reload Filter
        lbLastestFilterSettingAdopt: "The previous search criteria has been restored. Click 'Search' to refresh/ load the search result.",

        //Printing Error Message
        lbPrintingInfoLoading: "Printing Information is still on loading. Please wait. ",
        lbPrintingInfoLoadFailure: "Cannot get Printing Information. Please try again. ",

        //RT Issue
        lbPLRTChanged: "The quantity of Line #(LineNo) in \"P & L Charge At HBL Level\" is updated as the UOM was changed",
        lbGCCRRTChanged: "The quantity of Charge Item Line #(LineNo) is updated as the UOM was changed",


        lbInvoiceTotalAmountErr: "The Total Invoice Amount Tax Inclusive should not exceed CNY (maxNum) (or its equivalent in any other currency).",
        lbCheckCurrentGroupError: 'Please check error of current group.',
        lbMixSubtypeError: 'Export FP/FZ cannot mix with others invoice sub-type',


        lbExchangeRateUpdated: "Exchange Rate is updated to default. ",


        /***20200720 Local Invoice***/
        lbPrintDescriptionErr: "Print Description is not match.",
        lbMissingTaxCode: 'Please fill in Tax Rate Code.',
        lbMissingInvoiceSubType: 'Please fill in Invoice Sub-type.',

        lbFutureJobPeriod: 'Job month is in the future. Please check carefully before export.',

        lbQuickPreviewSwitchTab: 'Please either save or cancel the change before switching to another invoice.',
        lbQuickPreviewClosePanel: 'To close the \'Quick Preview\' panel, please either save or cancel the change first',

        lbCRSummaryMixAccountCode: 'CR Summary cannot not print across account code. ',
        lbPrintMixAccountCode: 'Cannot not print across account code. ',
        lbPrintMixARAP: 'Cannot not print across AR & AP. ',
        lbNoInvoiceAccountingPrint: 'Please select the invoice to batch accounting print/release/void.',

        lbNoSign: 'At least 1 user who prepared or approved this invoice does not have an e-signature.',


        lbSelectAccountCode: 'Please select account code.'

    },
    lbInvLoadingMsg: {
        lbLoading: "Loading...",
        lbServerValidation: "Server Validation...",
        lbSaving: "Saving...",

        //Posting
        lbServerValidation1: "Loading...Server Validation (1) Complete",
        lbServerValidation2: "Loading...Server Validation (2) Complete",
        lbCheckAccountLock: "Loading...Account is ready for posting",
        //lbPostComplete: "Posting Completed",
        lbPrinting: "Loading...Printing Invoice#(invoice_no)",
        lbSignPrinting: "Loading...Sign on Invoice#(invoice_no)",
    },
    lbInvMenu: {
        lbSearchAR: "Search Account Receivable",
        lbCreateAR: "Create Account Receivable",
        lbViewAR: "View Account Receivable",
        lbAmendAR: "Amend Account Receivable",

        lbSearchAP: "Search Account Payable",
        lbCreateAP: "Create Account Payable",
        lbViewAP: "View Account Payable",
        lbAmendAP: "Amend Account Payable"
    },
    lbInvARAP: {
        lbAGENT: 'Agent',
        lbCNS: 'Consignee',
        lbSHIPPER: 'Shipper',
        lbCARRIER: 'Carrier',
        lbSUPPLIER: 'Supplier',


        lbInvoice: "Invoice#",

        lbPreviewInvoice: "Preview Invoice",
        lbPrintOption: "Invoice Printing Option Setting",
        lbPreviewPLSheet: "Preview PL Sheet",
        lbPrint703XDNCN: "Print 703X DN/CN",
        lbPrintReverseInvoice: "Print Reversal Invoice",
        lbPrint7028IDNICN: "Print 7028 IDN/ICN",
        lbDebitNote: "Debit Note",
        lbCreditNote: "Credit Note",
        lbConfirmDoc: "发票确认件",
        lbInvoiceAttachment: "发票附件",
        lbPrintInvoice: "Printouts",
        lbTaxInvoice: "Tax Invoice",
        lbPreviewTaxInvoice: "Preview 7026 Tax Invoice",
        lbReceipt: "Receipt",
        lbPreviewReceipt: "Preview 7026 Receipt",

        lbMore: "More",

        lbCopyInvoice: "Duplicate Invoice",
        lbAutoInvoice: "Create DN to charge M+R HKG handling fee",
        lbInvoiceTnx2: "Generate the Inter-account Transaction Invoice",


        lbStatusControlPanel: "Status Update",
        lbUpdateFromWebInvoiceCore: "Update from Web Invoice Core",
        lbUpdateFromOPS: "Update from OPS",

        lbHeader: "Header",
        lbOffice: "Office",
        lbPreviewExcel: "Preview Excel",
        lbInvoiceIssuingOffice: "Invoice Issuing Office",
        lbAccountCode: "Account Code",
        lbAPRef: "AP Ref.",
        lbARType: "AR Type",
        lbAPType: "AP Type",
        lbJob: "Job#",
        lbIssueTo: "Issued To",
        lbIssueFrom: "Issued From",
        lbOverseas: "Overseas / Local",
        lbSo: "SO#",
        lbNominationAgent: "Nomination Agent",
        lbReleaseAgent: "Release Agent",
        lbConsignee: "Consignee",
        lbNotifyParty: "Notify Party",
        lbShipper: "Shipper",
        lbModeofTransport: "Mode of Transport",
        lbJobType: 'Job Type',
        lbService: "Service",
        lbInvoiceCurrency: "Invoice Currency",
        lbExchangeRate: "Exchange Rate",
        lbRemarks: "Remarks",

        lbDocUpload: "Document Upload",
        lbDocType: "Document Type",
        lbFile: "File",
        lbTimingAsPerRequirement: 'Timing as per Requirement',


        lbPL: "P&L",

        lbGeneralCharge: "General Charge",

        lbChequeRequest: "Cheque Request",

        lbStatus: "Status",
        lbLatestStatus: "Latest Status",

        lbGP: "GP",

        lbAccountPeriod: "Account Period",
        lbAccountLock: "Account Lock",
        lbLocked: "Locked",
        lbPreviewClose: "Preview / Run Closing",
        lbPreview: "Preview",
        lbRunClosing: "Run Closing",
        lbClosedPeriod: "Closed Period",
        lbRunClosingBy: "Run Closing By",
        lbRunClosingDate: "Run Closing Date",
        lbRunClosingExcel: "Run Closing Excel",
        lbDownload: "Download",


        lbNewRowHotkey: "New Row (Alt+N)",

        //20200421 Local Invoice
        lbTaxRate: "Tax Rate Code",
        lbSendTaxInvoiceTo: "Send Tax Invoice To",
        lbSendTaxInvoiceTo_Tooltip: "Send Tax Invoice To' blank / enter up to 3 email addresses.",
        lbRemarkOption: "备注格式",
        lbRemarkOption_Tooltip: "请选择合适的备注格式，该备注格式将用于税务发票上",
        lbRemarkOption1: "默认款",
        lbRemarkOption1_Remark: "",
        lbRemarkOption2: "默认款-简洁版",
        lbRemarkOption2_Remark: "简洁版不包含运输信息及不适用于月结发票。",
        lbRemarkOption3: "货物运输服务款",
        lbRemarkOption3_Remark: "请在Reference 1填写车牌，Reference 2填写运输货物名称。\n如需在發票上使用指定的起運地和到达地, 請在Reference输入“DEP@XXX”(例如:DEP@NINGBO -- 将起运地修改为NINGBO) 和/或 “ARR@XXX”, \n否則一律使用Job/ SO數據來默認起運地和到达地",
        lbRemarkOption4: "货物运输服务款-简洁版",
        lbRemarkOption4_Remark: "简洁版不包含运输信息及不适用于月结发票。请在Reference 1填写车牌，Reference 2填写运输货物名称。\n如需在發票上使用指定的起運地和到达地, 請在Reference输入“DEP@XXX”(例如:DEP@NINGBO -- 将起运地修改为NINGBO) 和/或 “ARR@XXX”, \n否則一律使用Job/ SO數據來默認起運地和到达地",

        lbGovernmentInvoiceNo: "Government Invoice#",
        lbInvoiceDate: "Invoice Date",
        lbTaxInvoicePrintDate: "Tax Invoice Printed Date",
        lbInvoiceSubtype: "Invoice Sub-type",
        lbInterTranscation: "Inter-account Transaction",
        lbPaymentRef: "Payment Ref.",
        lbPaymentRef_placeholder: "Fill in the Payment Ref. Leave it as blank if the Payment Ref. is not received",
        lbPaymentRef_infoTooltip: "Please tick this button if the Issued Party will issue/ had issued a Payment Ref. to us.",

        lbLocalName: "Name (Local)",
        lbENName: "Name (English)",
        lbRole: "Role",
        lbTaxID: "Company ID / Tax ID",
        lbCompanyPhone: "Company Phone",
        lbVATAddress: "VAT Address",
        lbVATAddressEN: "VAT Address (English)",
        lbLocalMainAddress: "Main Address (Local)",
        lbENMainAddress: "Main Address (English)",
        lbBankDetails: "Bank Details",
        lbSunAccountCode: "Sun Account Code",
        lbSunAccountCode4: "Sun 4 A/C Code",
        lbSunAccountCode6: "Sun 6 A/C Code",
        lbNotAssigned: "The (content_name) is not assigned. Please contact the relevant party to create it.",

        lbUpdateExchangeRate: "Update to default exchange rate",

        lbInterAccountTransaction: "Switch on the button: if it is required that when this invoice is being posted, system will auto-create an Incoming Debit Note (if this invoice is a Debit Note)/ Incoming Credit Note (if this invoice is a Credit Note) in the specified Account (e.g. 7021).\n\nSwitch off the button: if the above is not applied.",

        lbSetDefaultExchangeRateTooltip: 'The Exchange Rate is incorrect as it is not within the acceptable range.Click the \'Update to default exchange rate\' button to update the Exchange Rate to default.',

        lbReference: 'Reference',
        lbReferenceTooltip: 'You could fill in the reference of this invoice in this field for future reference. It will not be used and shown on the printouts and reporting.',

        //20211123 TW Invoice
        lbSEQRelease: 'Release',
        lbSEQVoid: 'Void',
        lbSEQReassign: 'Reassign',
        lbSEQTransfer: 'Reuse',
        lbCRSummaryHistory: 'CR Summary History',


        lbSnapshotStatus_no_history: 'No Snapshot',
        lbSnapshotStatus_data_processing: 'Processing',
        lbSnapshotStatus_generate_excel: 'Snapshot Created',
        lbSnapshotStatus_completed: 'Snapshot with Excel Created',
        lbSnapshotStatus_generate_excel_old_download_file: 'Snapshot Created',

        lbOptimizeOptions_refresh: 'Refresh Snapshot',
        lbOptimizeOptions_bestOptimize: 'Use Last Snapshot',
    },
    lbInvPL: {

        lbContainerLv: "P & L Charge At Container Level",
        lbHBLLv: "P & L Charge At (HBL_HAWB) Level",

        lbHBL: "HBL",
        lbHAWB: "HAWB",
        lbContainerSize: "Container / Size",
        //lbChargeCode: "Charge Code",
        lbChargeDesc: "Charge",
        lbPrintDesc: "Print Description",
        lbPL: "Profit / Loss",
        lbPLAgentPerc: "Agent %",
        lbPLTotal: "Total",
        lbPLAgent: "Agent",
        lbPLMR: "M + R",
        lbAgentCollect: "Agent",
        lbMRPrepaid: "M+R",
        lbBuyCost: "Buying (Cost)",
        lbSellingIncome: "Selling (Income)",
        lbTotal: "Total",
        lbAmountDR: "Amount (DR)",
        lbAmountCR: "Amount (CR)",
        lbLedgerAmountDR: "Amount (DR)\n(Base Currency)",
        lbLedgerAmountCR: "Amount (CR)\n(Base Currency)",

        lbCBM: "CBM",
        lbUOM: "UOM",
        lbCW: "C.W",

        lbTotal_Sym: "Total :",

        lbTotalInvAmt: "Total Invoice Amount Tax Exclusive: ",
        lbTotalInvTaxAmount: "Total Invoice Tax Amount: ",
        lbTotalInvAmtTax: "Total Invoice Amount Tax Inclusive: ",


    },
    lbInvGC: {
        lbGeneralChargesDetails: "General Charges Details",
        lbLine: "#",
        lbRefSB: "Reference",
        //lbChargeCode: "Charge Code",
        lbDRCR: "DR/ CR",
        lbChargeDesc: "Charge",
        lbPrintDesc: "Print Description",
        lbCurrency: "Currency",
        lbUnitPrice: "Unit Price",
        lbExchangeRate: "Exchange Rate",
        lbQty: "QTY",
        lbUOM: "UOM",
        lbAmountDR: "Amount (DR)",
        lbAmountCR: "Amount (CR)",
        lbLedgerAmountDR: "Amount (DR)\n(Base Currency)",
        lbLedgerAmountCR: "Amount (CR)\n(Base Currency)",
        lbTotal: "Total :",
        lbTotalGeneralChargesExclusive: "Total General Charges Tax Exclusive:",
        lbTotalGeneralChargesTaxAmount: "Total General Charges Tax Amount:",
        lbTotalGeneralChargesInclusive: "Total General Charges Tax Inclusive:",

        lbJobNo: "Job No.",
        lbHbl: "(hbl_hawb) No. / SO No. / (cbm_cw) / Package",
        lbContainer: "Container No. / Size ((hbl_hawb) No.)",

        lbTaxCode: "Tax Rate Code",
        lbTaxRate: "Tax Rate",
        //lbUnitPriceTaxInclusive: "Unit Price\n(Tax Inclusive)",
        //lbAmountDRTaxInclusive: "Amount (DR)\nTax Inclusive",
        //lbAmountCRTaxInclusive: "Amount (CR)\nTax Inclusive",
        lbTaxAmount: "Tax Amount",
        lbLedgerTaxAmount: "Tax Amount\n(Base Currency)",

        lbAmount: "Amount",
        lbLedgerAmount: "Amount\n(Base Currency)",

        lbAll: "All",
        lbTaxExclusive: "Tax Exclusive",
        lbTaxInclusive: "Tax Inclusive",

        lbInterTran: "Inter-account Transaction",

        lbPrintDescriptionTooltip: "Print Description will be taken and printed on the invoice printout for each charge item (if applicable)",
        lbCurrencyTooltip: "Charge Item Line Currency",
        lbUnitPriceTooltip: "The tax exclusive unit price in the selected currency",
        lbUnitPriceExchangedTooltip: "The tax inclusive unit price in the 'Invoice Header Currency'",
        lbAmountDebitTooltip: "Tax Exclusive Amount = Tax Exclusive Unit Price x Exchange Rate x QTY",
        lbAmountCreditTooltip: "Tax Exclusive Amount = Tax Exclusive Unit Price x Exchange Rate x QTY",
        lbTaxCodeTooltip: "The appropriate tax code for each charge item line. The 'Tax Rate' will be refreshed according to the selected Tax Code.",
        lbTaxRateTooltip: "The tax rate according to the selected 'Tax Code'. (The system calculated actual tax rate according to the provided 'Tax Amount'.)",
        lbUnitPriceTaxTooltip: "The tax inclusive unit price under the invoice header currency",
        lbAmountDebitTaxTooltip: "Tax Inclusive Amount = Tax Exclusive Amount + Tax Amount",
        lbAmountCreditTaxTooltip: "Tax Inclusive Amount = Tax Exclusive Amount + Tax Amount",
        lbAmountTaxTooltip: "Tax Amount is either the system calculated Tax Amount of each charge item line according to the selected 'Tax Code' or the user specified Tax Amount.\n\nTax Amount(if calculate by system) = Tax Exclusive Amount x Tax Rate",

        lbInterAccountTransactionTooltip: "Switch on the button: if the charge item line is required to charge the specified Account. When this invoice is being approved (at the first time), system will auto-create a Debit Note in the same Account (e.g. 703X). Or you could trigger the auto-create process when this invoice is under 'Prepared' by clicking \"More Actions > Generate the Inter - account Transaction Invoice\".\nNote: If the invoice involves multiple Jobs, this action can't perform.\n\nSwitch off the button: if the above is not applied.",

        lbChargeDesc_View: "Print Description [Code]",
        lbViewModeTooltip: "You could change to different View Modes to view different information in different formats for facilitating your works. \n\nAlt+ A : Hide the functional buttons on each charge item line when editing the invoice \nAlt+ L : Display the detailed amounts in base currency",
        lbViewMode: "View Mode",
        lbAlt_T: "Alt+T switching unit price tax mode",
        lbAlt_QW: "Alt+Q : changes to previous View Mode\nAlt+ W : changes to next View Mode",
    },
    lbInvCR: {

        lbCRDetails: "Cheque Request Details",
        lbCRChargeSummary: "Quick Summary of Cheque Request",
        lbCRDetailsItem: "Cheque Request Charge Details",


        lbAvailableDate: "Available Date :",
        lbFreeToCombine: "Free to Combine",
        lbFreeToCombineTooltips: "Tick this checkbox to let Accounting know they can combine this cheque request with other cheque requests, \nwhich are for the same Issued To and prepared by the same operator, to issue one cheque for payment.",
        lbPaymentMarker: "Payment Marker :",
        lbForeignCurrency: "Foreign Currency ",


        lbLine: "#",
        lbJob: "Job#",
        lbReference: "Reference",
        lbChargeCode: "Charge Code",
        lbChargeDesc: "Charge Description",
        lblRemarks: "Remarks",
        lbUnitPriceBaseCurrency: "Unit Price\n(Base Currency)",
        lbUnitPriceForeignCurrency: "Unit Price\n(Foreign Currency)",
        lbQuantity: "QTY",
        lbUOM: "UOM",
        lbAmtBaseCurrency: "Amount\n(Base Currency)",
        lbAmtForeignCurrency: "Amount\n(Foreign Currency)",
        lbChequeCurrency: "Cheque Currency",

        lbTotal: "Total :",

        lbExchangeRate: "Exchange Rate to (base_currency)",

        lbTaxAmountBaseCurrency: "Tax Amount\n(Base Currency)",
        lbTaxAmountForeignCurrency: "Tax Amount\n(Foreign Currency)",

        lbLedgerTotalAmountBaseCurrency: "Amount\n(Converted to Base Currency)",
        lbLedgerAmountTaxBaseCurrency: "Tax Amount\n(Converted to Base Currency)",


        lbTotalAmount: "Amount",
        lbAmountTax: "Tax Amount",

        lbVatPeriod: "VAT Period",
        lbFormatTypeNo: "格式代號",
        lbCompanyID: "Company ID",
        lbTaxCreditNo: "扣抵代號",
        lbLocalInvoiceNo: "Local Invoice#",
    },
    lbInvARAPType: {
        lbPL: "Profit Share Invoice",
        lbDN: "Debit Note",
        lbCN: "Credit Note",
        lbIDN: "Incoming Debit Note",
        lbICN: "Incoming Credit Note",
        lbCR: "Cheque Request"
    },
    lbInvSubType: {
        lbP: "普通发票或其它发票",
        lbZ: "增值税专用发票",
        lbEP: "电子普票",
        lbEZ: "电子专票",
        lbFP: "全电普票",
        lbFZ: "全电专票",
        lbTY: "統一發票",
        lbR: "收據",
        lbTRANSFER: "轉賬",
        lbCHEQUE: "支票",
        lbTY31: "三聯式",
        lbTY32: "二聯式",
        lbDSDF: "代收代付",

        lbSTAMP: "Stamp Duty",
        lbNSD: "No Stamp Duty",

        lbWCT: "With Credit Term",
        lbNCT: "No Credit Term",
    },
    lbInvStatus: {
        lbAction: "Action",
        lbStatus: "Status",

        lbLine: "#",
        lbType: "",
        lbAccountCode: "Account Code",
        lbInvoice: "Invoice#",
        lbAPRef: "AP Ref.",
        lbLatestStatus: "Latest Status",
        lbStatusRemarks: "Status Remarks",
        lbInvoicceToFrom: "Invoice To / From",
        lbInvAmtInvCurrency: "Invoice Amount\n(Invoice Currency)",
        lbExchangeRate: "Exchange Rate",
        lbInvAmtBaseCurrency: "Invoice Amount\n(Base Currency)",
        lbIncomeBaseCurrency: `Income\n(Base Currency)`,
        lbCostBaseCurrency: "Cost\n(Base Currency)",
        lbSettledAmount: "Settled Amount\n(Invoice Currency)",
        lbTotal: "Total:",
        lbGP_Title: "GP",
        lbGP: "GP:",
        lbGP_SO: "GP by SO",
        lbStatusHistory: "Status History",
        lbARAP: "AR / AP#",
        lbPosition: "Position",
        lbUser: "User",
        lbDateTime: "Updated Time",
        lbRemarks: "Remarks",


        NewInvoiceBtn: "New",
        ApprovedInvoiceBtn: "Approve",
        ClosedInvoiceBtn: "Close",
        ClosedReversedInvoiceBtn: "Close",
        DeletedInvoiceBtn: "Delete",
        //NewReversedInvoiceBtn: "New Resersed",
        PostedInvoiceBtn: "Post",
        PostReversedInvoiceBtn: "Post",
        PreparedInvoiceBtn: "Prepare",
        PrintedInvoiceBtn: "Print",
        RejectedInvoiceBtn: "Reject",
        ReleasedInvoiceBtn: "Release",
        ReversedInvoiceBtn: "Reverse",
        ExportedInvoiceBtn: "Export",
        ImportedInvoiceBtn: "Import",
        PendingExportAutoBtn: "Pending Auto Export",

        SupvApprovedInvoiceBtn: "Approve (1st level)",
        SupvRejectedInvoiceBtn: "Reject (1st level)",
        MgrApprovedInvoiceBtn: "Approve (2nd level)",
        MgrRejectedInvoiceBtn: "Reject (2nd level)",

        NewReversalGroupBtn: "Approve (2nd level)",
        ExportReversalGroupBtn: "Export",
        ImportReversalGroupBtn: "Import",
        PostReversalGroupBtn: "Post",

        ExportedAutoBtn: "Exported (Auto)",

        AccountingPrintedInvoiceBtn: "Accounting Print",


        lbInvoiceBtn: "Invoice",
        lbReSignInvoiceBtn: "Sign on Invoice",
        lbSignedInvoiceBtn: "Invoice with e-Sign",
        lbPLSheetBtn: "PL Sheet",
        lbExportFileBtn: "Export File",
        lbTaxInvoiceBtn: "Tax Invoice",
        lbReceiptBtn: "Receipt",

        //lbGP: "GP",
        lbGPAmount: "GP Amount",
        lbGPAmountTooltip: "All - Both Tax Exclusive GP and Tax Inclusive GP will be shown\nTax Exclusive - Only the Tax Exclusive GP will be shown\nTax Inclusive - Only the Tax Inclusive GP will be shown",

        lbExchangeRateUpdated: "Exchange Rate is updated from (origin_exchange_rate) to (new_exchange_rate) by (updated_by_name). ",

        lbGrouped: "Exported for Batch Payment(Group# (group_no))",
        lbGroupedAuto: "Group# (group_no) (Number of invoice: (group_invoice_count))",

        lbInvoicceToFromLocal: "Invoice To / From (Local Language)",
        lbGovernmentInvoiceNo: "Government Invoice #",

        lbRollbackExport: "Rollback from Exported",
        lbRollbackImport: "Rollback from Imported [Government Invoice#: (gov_invoice_no), Invoice Date: (invoice_dt) were reset as empty]",
        lbRollbackAccountingPrinted: "Rollback from (Accounting_Printed) [Government Invoice#: (gov_invoice_no), Invoice Date: (invoice_dt) were reset as empty]",

        lbProcess_pending: 'Pending',
        lbProcess_empty: 'Not Applicable',
        lbProcess_fail: 'Failure',
        lbProcess_success: 'Success',


        lbSalesCode: "Sales Code",
        lbShipperName: "Shipper Name",
        lbSO: "SO#",
    },

    lbInvGPSummary: {
        lbSummaryTable: "GP Quick Summary Of Job#:",
        lbCurrency: "Currency",
        lbTotalIncome: "Total Income",
        lbTotalCost: "Total Cost",
        lbGP: "GP",
        lbGPPercent: "GP%"

    },

    lbInvColorDesc: {

        JobOutstandingPending1: "Pending completed more than 2 job months",
        JobOutstandingPending2: "Pending completed within 1 job month",
        JobOutstandingPending3: "Pending completed less than 1 job month",


        ARPendingCreation1: "Pending creation more than 2 days after ETD",
        ARPendingCreation2: "Pending creation within 2 days after ETD",
        ARPendingCreation3: "Pending creation on or before ETD",

        ARNewInvoice1: "Pending preparation more than 2 days after ETD",
        ARNewInvoice2: "Pending preparation within 2 days after ETD",
        ARNewInvoice3: "Pending preparation on or before ETD",


        ARPreparedInvoice1: "Pending printing more than 3 days after ETD",
        ARPreparedInvoice2: "Pending printing within 3 days after ETD",
        ARPreparedInvoice3: "Pending printing on or before ETD",
        ARReleasedInvoice1: "AR Released",
        //ARReleasedInvoice1: "Pending printing more than 3 days after ETD",
        //ARReleasedInvoice2: "Pending printing within 3 days after ETD",
        //ARReleasedInvoice3: "Pending printing on or before ETD",

        ARPrintedInvoice1: "Pending posting more than 2 days after printing",
        ARPrintedInvoice2: "Pending posting within 2 days after printing",
        ARPrintedInvoice3: "Pending posting on date of printing",
        ARNewReversedInvoice1: "Pending posting more than 2 days after printing",
        ARNewReversedInvoice2: "Pending posting within 2 days after printing",
        ARNewReversedInvoice3: "Pending posting on date of printing",

        ARPostedInvoice1: "Pending period close 10 days after AR creation month",
        ARPostedInvoice2: "Pending period close within 10 days after AR creation month",
        ARPostedInvoice3: "Pending period close for AR created within same calendar month",
        ARReversedInvoice1: "Pending period close 10 days after AR creation month",
        ARReversedInvoice2: "Pending period close within 10 days after AR creation month",
        ARReversedInvoice3: "Pending period close for AR created within same calendar month",
        ARPostReversedInvoice1: "Pending period close 10 days after AR creation month",
        ARPostReversedInvoice2: "Pending period close within 10 days after AR creation month",
        ARPostReversedInvoice3: "Pending period close for AR created within same calendar month",

        ARPendingApproval1: "Pending approval more than 2 days after preparation",
        ARPendingApproval2: "Pending approval within 2 days after preparation",
        ARPendingApproval3: "Pending approval on date of preparation",


        ARPendingExport3: "Pending export",

        ARPendingImport3: "Pending import",

        ARPostedFailure3: "AR Posting to SUN Failed",
        ARPostedSuccess3: "AR Posted to SUN Success",
        ARPostedPending3: "AR Pending Ack. from SUN",


        /*AP*/
        APPendingCreation1: "Pending creation more than 5 days after ETD",
        APPendingCreation2: "Pending creation within 5 days after ETD",
        APPendingCreation3: "Pending creation on or before ETD",

        APNewInvoice1: "Pending preparation more than 30 days after ETD",
        APNewInvoice2: "Pending preparation within 30 days after ETD",
        APNewInvoice3: "Pending preparation on or before ETD",

        UploadDocMissing1: "Pending Upload Document more than 30 days after ETD",
        UploadDocMissing2: "Pending Upload Document within 30 days after ETD",
        UploadDocMissing3: "Pending Upload Document on or before ETD",

        APRefMissing1: "Pending AP Ref more than 30 days after ETD",
        APRefMissing2: "Pending AP Ref within 30 days after ETD",
        APRefMissing3: "Pending AP Ref on or before ETD",

        APPreparedInvoice1: "Pending 1st level approval more than 2 days",
        APPreparedInvoice2: "Pending 1st level approval within 2 days",
        APPreparedInvoice3: "Pending 1st level approval after prepared",

        APSupvApprovedInvoice1: "Pending 2nd level approval more than 2 days after 1st level approval",
        APSupvApprovedInvoice2: "Pending 2nd level approval within 2 days after 1st level approval",
        APSupvApprovedInvoice3: "Pending 2nd level approval on date of 1st level approval",
        //APReleasedInvoice1: "pending 1st level approval more than 2 days",
        //APReleasedInvoice2: "pending 1st level approval within 2 days",
        //APReleasedInvoice3: "pending 1st level approval after prepared",
        APReleasedInvoice1: "AP Released",
        APSupvRejectedInvoice1: "AP Rejected",
        APMgrRejectedInvoice1: "AP Rejected",



        APMgrApprovedInvoice1: "Pending posting more than 2 days after approval",
        APMgrApprovedInvoice2: "Pending posting within 2 days after approval",
        APMgrApprovedInvoice3: "Pending posting on date of approval",
        APNewReversedInvoice1: "Pending posting more than 2 days after approval",
        APNewReversedInvoice2: "Pending posting within 2 days after approval",
        APNewReversedInvoice3: "Pending posting on date of approval",

        APPostedInvoice1: "pending period close 10 days after AR creation month",
        APPostedInvoice2: "pending period close within 10 days after AR creation month",
        APPostedInvoice3: "pending period close for AR created within same calendar month",
        APReversedInvoice1: "pending period close 10 days after AR creation month",
        APReversedInvoice2: "pending period close within 10 days after AR creation month",
        APReversedInvoice3: "pending period close for AR created within same calendar month",
        APPostReversedInvoice1: "pending period close 10 days after AR creation month",
        APPostReversedInvoice2: "pending period close within 10 days after AR creation month",
        APPostReversedInvoice3: "pending period close for AR created within same calendar month",

        tooltips998: "Not Applicable",
        tooltips999: "Colour cannot be show due to missing data",

        APPostedFailure3: "AP Posting to SUN Failed",
        APPostedSuccess3: "AP Posted to SUN Success",
        APPostedPending3: "AP Pending Ack. from SUN",

    },
    lbInvSearch: {
        lbMenuItem_OPEN: "Outstanding",
        lbMenuItem_REOPEN: "Outstanding",
        lbMenuItem_LOCK: "Completed",
        lbMenuItem_LOCKGP: "Completed - GP Locked",

        lbMenuItem_UpdateAPRef: "Update AP Ref.",
        lbMenuItem_UploadDoc: "Upload Document",
        lbMenuItem_CRSummary: "Print CR Summary",
        lbMenuItem_UpdateSubTypeTaxRate: "Update 'Invoice Sub - type' and 'Tax Rate Code'",
        lbMenuItem_NoNextActivity: "No Next Activity",
        lbMenuItem_RePost: "Re-post",

        lbCreateNewAP: "Create New AP",
        lbCreateNewAR: "Create New AR",
        lbExportToExcel: "Export to Excel",
        lbColumnOption: "Column Options",

        lbQuickPreview: "Quick Preview",
        lbQuickPreviewTooltips: "Preview selected invoice(s) in the popup window",
        lbQuickPreviewHeader: "Quick Preview of [invoice_no] ",
        lbQuickPreviewHeaderTooltips: "In Quick Preview, you can't edit the invoice/ update the invoice's status. Click 'Open in New Tab' to open the invoice in a new tab for editing/ updating status (if applicable).",
        lbQuickPreviewHeaderNewTabButton: "Open In New Tab",

        lbType: "Type",
        lbAccountCode: "Account Code",
        lbGP: "GP\n(Base Currency)",
        lbGPPercent: "GP%",
        lbForeignGP: "GP\n(Reference)",
        lbJob: "Job#",
        lbJobPeriod: "Job Month", //Group Panel
        //lbCurrentJobMonth: "Current Job Month", //Group Panel
        lbFutureJobMonth: "Future Job Month",
        lbJobCreatedBy: "Job Created By",
        lbJobCreatedDate: "Job Created Date",
        lbSearchJobCreatedBy: "Search Job Created By",
        lbInvoicceToFrom: "Invoice To / From",
        lbInvoicceToFromLocal: "Invoice To / From (Local)",
        lbSearchIssueParty: "Search Invoice To / From",

        lbInvoice: "Invoice#",
        lbAPRef: "AP Ref.",
        lbAPRefInputted: "AP Ref. Inputted Flag",
        lbSearchAPRef: "Search AP Ref.",
        lbReversingInvoice: "Reversing Invoice#",

        lbSO: "SO#",

        lbHBL: "HBL# / HAWB#",
        lbMBL: "MBL# / MAWB#",
        lbConsignee: "Consignee",
        lbSearchConsignee: "Search Consignee",
        lbReleaseAgent: "Release Agent",
        lbSearchReleaseAgent: "Search Release Agent",
        lbNominationAgent: "Nomination Agent",
        lbSearchNominationAgent: "Search Nomination Agent",

        lbJobService: "Job Service",
        lbCostCentre: "Cost Centre Code",
        lbModeOfTransport: "Mode of Transport",
        lbJobType: 'Job Type',
        lbSearchJobService: "Search Job Service",
        lbVessel: "Vessel",
        lbVoyage: "Voyage",
        lbFlightNo: "Flight#",
        lbFeederETD: "Feeder ETD",
        lbETD: "ETD",
        lbETA: "ETA",
        lbCarrier: "Carrier",
        lbSearchCarrier: "Search Carrier",

        lbLatestStatus: "Invoice Status",
        lbJobInvoicingStatus: "Job Invoicing Status",
        lbInvUpdatedBy: "Invoice Updated By",
        lbSearchInvUpdatedBy: "Search Invoice Updated By",

        lbInvUpdatedDate: "Invoice Last Updated Time",
        lbStatusUpdatedDate: "Status Last Updated Time",
        lbInvOffice: "Job Office",
        lbCreatorOffice: "Invoice Create Office",

        lbInvAmtInvCurrency: "Invoice Amount\n(Invoice Currency)",
        lbSettledAmount: "Invoice Settled Amount\n(Invoice Currency)",
        lbInvoiceFullySettled: "Invoice Fully Settled",
        lbSettled: "Settled",
        lbClosePeriod: "Close Period",

        lbSalesCode: "Sales Code",
        lbPaymentMarker: "Payment Marker",
        lbSearchSalesCode: "Search Sales Code",
        lbSunFile: "Sun Account File",
        lbAction: "Action",


        lbExportExcelTooltips: "You can export a maximum of 10,000 records at a time.",
        //lbSmartSearchTooltips: "This searching can search [Job no, So#, HBL, MBL, Invoice#, Issue Party Name, Container#, Group#]",
        lbSmartSearchTooltips: "Enter Invoice#, Job#, SO#, HBL#/ HAWB#, MBL#/ MAWB#, Issue Party Name, Container# or Group# to search.Click the Customized Filter for advance searching.\n\nOnce you entered the keyword in this field, you could click 'Tab' and then press 'Enter' to perform searching.",
        msgSureToChangeState: "Are you sure the following records change status to @action?",


        lbGovernmentInvoiceNo: "Government Invoice#",
        lbInvoiceDate: "Invoice Date",
        lbInvoiceSubtype: "Invoice Sub-type",
        lbPaymentRefFlag: "Payment Ref. Flag",
        lbPaymentRef: "Payment Ref.",
        lbTaxCode: "Tax Rate Code",
        lbARInterTran: "AR Inter-account Transaction",
        lbAPInterTran: "AP Inter-account Transaction",
        lbGroupNo: "Group#",
        lbExportFile: "Export File",
        lbTaxAmount: "Tax Amount",

        lbBatchPaymentARTooltips: "Batch Payment is enabled for this issue party",

        lbClosed: "Closed Flag",
        lbReference: "Reference",
        lbJobMonth: "Job Month",
        lbContainersNo: "Container#",

        lbExchangeRate: "Exchange Rate",
        lbInvAmtBaseCurrency: "Invoice Amount\n(Base Currency)",
        lbHasUploadDoc: "Doc",
        lbBatchPayment: "AR Batch Payment",

        lbProcessStatus: "Sun Process Status",
        lbJournalNumber: "Journal#",
        lbSumbitXML: "Latest Submit XML",
        lbReturnXML: "Latest Response XML",

        lbInv_coloader_bl: 'Coloader BL#',
        lbInv_mso_no: 'Carrier Booking#',
        lbInv_so_ref: 'S/O Ref.',
        lbInv_shp_name: 'HBL Shipper Name',
        lbInv_job_min_eta: 'Earliest ETA',
        lbInv_o_ag_name: 'Origin Agent Name',
    },
    lbInvGroup: {
        lbTitle: "Batch Payment for ",
        lbBatchTaxInvTitle: "Batch Tax Invoice for ",
        lbGroup: "Group#",
        lbAccountCode: "Account Code",
        lbCurrency: "Currency",
        lbInvoiceSubType: "Invoice Sub-type",
        lbTaxRateCode: "Tax Rate Code",

        lbNoOfSelectedInvoice: "No. of Selected Invoice",
        lbTotalAmountofSelectedInvoice: "Total Amount of Selected Invoice\n(Invoice Currency)",

        lbCurrenctGroup: "Current Grouping for Export",
        lbCurrenctGroup_TaxInvoice: "Current Grouping for Tax Invoice",
        lbHistory: "History (Recent 2 Months)",
        lbNoOfGroupedInvoice: "No. of Grouped Invoice",
        lbTotalAmountofGroupedInvoice: "Total Amount of Grouped Invoice\n(Invoice Currency)",
        lbGroupedDate: "Grouped Date",
        lbExportDate: "Export Time",
        lbExportBy: "Export By",
        lbExportFile: "Export File",

        lbPrintedDate: "Printed Time",
        lbPrintedBy: "Printed By",
        lbPrintedFile: "Print File",

        lbCurrentGroupDescription: "The following table shows the invoices, which belong to the above party and not yet perform data export. For those invoice(s) which belong to the same account under the same invoice currency will be treated as the same group for Batch Payment.\nPlease select invoice(s) for exporting the data in a group for Batch Payment.Press the 'Export' button on the above after you have completed the selection. ",
        lbCurrentGroupDescription_TaxInvoice: "The following table shows the invoices, which belong to the above party and not yet perform data export. For those invoice(s) which belong to the same account under the same invoice currency will be treated as the same group for Batch Print Tax Invoice.\nPlease select invoice(s) for exporting the data in a group for Batch Payment.Press the 'Accounting Print' button on the above after you have completed the selection. "
    },
    lbInvImport: {
        lbTitle: 'Import Document',
        lbIdx: '#',
        lbInvoiceNo: 'Invoice#',
        lbGovernmentInvoiceNo: "Government Invoice#",
        lbInvoiceDate: "Invoice Date",

        lbImportId: 'Import Reference#',
        lbImportIdTooltip: "'Import Reference#' will be generated when you have uploaded an Import File onto the Web Invoice system. This number is for the purpose of reference and support.",
        lbGroupNo: 'Group Invoice#',
    },

    /*Analytics */
    lbAnalytics: {
        lbWebInvoiceGP: "Web Invoice GP",
        lbWebInvoiceGP1: "GP by Job Month, Service and Mode",
        lbWebInvoiceGP2: "GP by POL and Office",
        lbWebInvoiceGP3: "GP by CBM, Container, CW and Shipment",
        lbWebInvoiceGP4: "GP by Invoice and Shipment Details",
        lbWebInvoiceSalesGP: "Web Invoice Sales GP",
    },


    /*Template Library*/
    lbTmplibAdoptPenal: {
        lbApply: 'Apply Once',
        lbUndo: 'Undo Once',
        lbFavorite: 'Favorite',
        lbEdit: 'Edit',
        lbView: 'View',
        lbCreateNewTemplate: 'Create Template',
        lbRefresh: 'Refresh the list',
        lbInfo: 'Select the template to be applied.',
    },
    lbTmplibSearch: {
        lbMyTemplate: "My Template",
        lbMarket: "Market",


        lbModule: "Module",
        lbCategory: "Category",
        lbTemplateName: "Template Name",
        lbDescriptionOfTemplate: "Template Description",
        lbOwner: "Owner",
        lbLastUpdate: "Last Update",
        lbTemplateId: "Template Id",

        lbTagCould: "Top Tags: ",
        lbTags: "Tags",




        lbRecordUpdatedMsg: "[TemplateName] has been updated. You could continue to use it.",

        lbCreateNewTemplate: "Create Template",



        lbAdded: "Added",

        lbMenuItem_Delete: "Delete",
        lbMenuItem_AddToMyTemplate: "Add To My Template",
        lbMenuItem_RemoveFormMyTemplate: "Remove From My Template",


        /**Tooltip**/
        lbPrivate: "Private",
        //lbMarket: "Market", //duplicate key
        lbSharingInMarket: "Sharing in Market",
        lbShareByOffice: "Sharing By Office",
        lbAddedFromMarket: "Added from Market",
        lbToMainOffice: "Your Main Office",
        lbGlobal: "Global",

        lbTooltips_Favorite: "Favorite",
        lbTooltips_Active: "Active",
        lbTooltips_Inactive: "Inactive",

        lbCreatedFromMyTemplate: "Created from My Template",
        lbAddedIntoMyTemplate: "Already Added into My Template",

        lbTooltips_MyTemplate: "The templates created by you or added from the Market",
        lbTooltips_Market: "To browse and get the templates from the Market, which are created by others and sharing with you",
        lbSmartSearchTooltips: "This searching can search [Template Name, Description of Template and Tags]",

    },
    lbTmplibActionModal: {
        lbTitle_: '', //Avoid console log
        lbTitle_Delete: 'Are you sure to delete the following templates?',
        lbTitle_AddToMyTemplate: 'Are you sure to add the following templates to My Template?',
        lbTitle_RemoveFormMyTemplate: 'Are you sure to remove the following templates to My Template?',
        lbDelete_Text1: 'Sure to delete the selected template?',
        //lbDelete_Text1: 'Sure to add the selected template to My Template?',
        lbDelete_Text2: 'Sure to delete the selected template? after deletion, this shared template will be removed from all user\'s template library',
    },
    lbTmplibDetails: {
        lbModule: "Module",
        lbCategory: "Category",
        //lbCategory: "Category",
        lbTmpLib: "[#(Tmplib_id)]",
        lbTmpName: "Template Name",
        lbTmpId: "Template ID",
        lbTmpCreatedBy: "Created by",
        lbTmpDescription: "Description",
        lbReleaseNote: "Release Note",
        lbShareLink: "Share Link",
        lbShareOffice: "Share Office",

        lbPrivacy: "Share",
        lbPrivate: "Private",
        lbPublic: "Market",
        lbByOffice: "Market (Specific Office)",
        lbToMainOffice: "Your Main Office",
        lbGlobal: "Global",

        lbShareToOffice: "Share to office",
        lbLinkShare: "Link Share",
        lbEnabled: "Enabled",
        lbHashTag: "Tags",
        lbColor: "Display Color",

        lbColorScheme1: "Gold",
        lbColorScheme2: "Light Blue",
        lbColorScheme3: "Blue",
        lbColorScheme4: "Dark Blue",
        lbColorScheme5: "Purple Blue",

        lbTmpStatus: "Template Status",



        /**Tooltip**/
        lbTooltips_Favorite: "Favorite",
        lbTooltips_Active: "Active",
        lbTooltips_Inactive: "Inactive",

        lbTooltips_PrivacySettings: "Private: the template will be visible to you only \
                                    \n\nMarket: the template will be shared and available in the market.Other users can add this template from the market and use it \
                                    \n\nMarket(Specific Office): the template will be shared and available in the market.Users from the specific office(s) can add this template from the market and use it \
                                    \n\nYour Main Office: the template will be shared with all users in your main office.They can use it directly(don't need to add this template from the market before use) \
                                    \n\nGlobal: the template will be shared with all users.They can use it directly(don't need to add this template from the market before use) ",
        lbTooltips_ShareLink: "You could share this template with your teammates/ other users by an URL. They could get this template by opening this URL.",
        lbTooltips_ReleaseNote: "As this template is sharing with other users, please write a 'Release Note' for explaining what you have updated when you are saving the template. This is for other users' easy reference.",
        lbTooltips_Hashtag: "Tags allow the user to organize thousands of templates in the simplest way.The user could quickly filter out and search for the specific template(s) by applying and using Tags to search.",
        lbTooltips_DisplayColor: "When you are applying the template, the record(s) created by this template will be marked as your selected Display Colour for your easy reference.",

        lbTooltips_SelectCounter: "Number of the selected options",
    },
    lbTmplibEditError: {
        lbDuplicateName: "This template name is used.",
        lbUpdateFailure: "Template Library Update Failure. Please Contact Administrator.",
        lbSaveSuccess: 'Template Library saved successfully.',
        lbNoPermission: 'You have no permission',
        lbFillInRequiredFields: 'Please fill in all required fields in Template Content.',
        lbCopyToClipboard: 'Share Link is copied.',
        lbGetTemplateFailure: 'This template can\'t be added into your Template Library because it is a private template.',
        lbGetTempalteSuccess: 'This template has been added to your Template Library.',
    },
    /*Template Library - Add Text According to Master Table*/
    lbTmplibModule: {
        WebInvoice: "Web Invoice",
        BMS: "BMS"
    },
    lbTmplibCategory: {
        INV_7023: "7021",
        INV_7025: "7025",
        INV_7026: "7026",
        INV_7028: "7028",
        INV_7030: "7030",
        INV_703X: "703X",
        INV_7048: "7048",
        INV_GCSOPMS: "General Charge SOPMS",
        INV_GCSOPMS_7048: "General Charge SOPMS 7048",
        INV_PRINT_OPT: "Print Options",
        BMS_MARKS_GOODS: "Marks & No. and Goods Description",

    },
    lbTmplibStep: {
        INV_7023_step1: 'Template Setting',
        INV_7023_step2: 'Template Content',
        INV_7025_step1: 'Template Setting',
        INV_7025_step2: 'Template Content',
        INV_7026_step1: 'Template Setting',
        INV_7026_step2: 'Template Content',
        INV_7028_step1: 'Template Setting',
        INV_7028_step2: 'Template Content',
        INV_703X_step1: 'Template Setting',
        INV_703X_step2: 'Template Content',
        INV_7030_step1: 'Template Setting',
        INV_7030_step2: 'Template Content',
        INV_7048_step1: 'Template Setting',
        INV_7048_step2: 'Template Content',
        INV_GCSOPMS_step1: 'Input',
        INV_GCSOPMS_step2: 'Output',
        INV_GCSOPMS_7048_step1: 'Input',
        INV_GCSOPMS_7048_step2: 'Output',
    },
    lbTmplibFormTitle: {
        INV_Setting: "Setting",
        INV_GeneralCharge: "Pre-defined General Charge Item",
        INV_PLHBL: "Pre-defined Charge Item in 'P & L Charge At House Bill Level' table",
        INV_ChequeRequest: "Pre-defined Cheque Request Item",
        INV_GCSOPMS_INPUT: "Searching Criteria",
        INV_GCSOPMS_OUTPUT: "Pre-defined General Charge Item",
        INV_GCSOPMS_SETTING: "Setting",
        INV_PrintOption: "Print Options",
        BMS_MARKS_GOODS: "Marks & No. and Goods Description",
    },
    lbTmplibFormTitle_Tooltip: {
        INV_Setting: "Please select the preferable 'Account Code', 'Office', and 'Invoice Type' as the basis of the 'Template Setting' of this template. The 'Template Setting' will be used to customize the columns on the 'Template Content' and filter out the option of selection list on 'Template Content'.\nIt is recommended that you should always check the content after adopting the template onto an invoice.",    },
    lbTmplibFieldTitle_Tooltip: {
        INV_GC_Reference: "Tell the system how to generate the General Charge Items.",
        INV_CR_Reference: "Tell the system how to generate the Cheque Request Items.",
        INV_GCSOPMS_OUT_BaseOn: "Tell the system how to generate the General Charge Items.",
        INV_GCSOPMS_OUT_Override: "0 = All users could override \
                                \n10 = Invoice user, supervisor and manager could override \
                                \n20 = Invoice supervisor and manager could override \
                                \n30 = Invoice manager could override \
                                \n99 = No one can override",
        INV_GCSOPMS_SETTING_ValidationGroup: "Please fill in a numerical value in 'Validation Group' & 'Tags' for easy reference. \nThe system will disallow if contradiction on \"ETD Effective Date Range\" and/or \"Invoice Date Effective Date Range\" was found between different SOPMS in the same Validation Group.\n\nPlease refer to *** M:\\Dept\\Future\\SOPMS  *** about the Validation Group Master List\n\n** Fill in \"0\" if the validation checking is not required",
        INV_GC_Qty: "If QTY in the template is 0, the system will auto - replace the 'Qty' according to the invoice's information in the following situations. \
                     \n \n1. Reference = 'Each Job'.A charge code is selected.UOM = 'Bill'.  -- > Qty will auto - replace as the number of bills in each job\
                     \n \n2. Reference = 'Each HBL/HAWB'.A charge code is selected.UOM = 'Container'.  -- > Qty will auto - replace as the number of containers in each HBL/HAWB",
        INV_CR_Qty: "If QTY in the template is 0, the system will auto - replace the 'Qty' according to the invoice's information in the following situations. \
                     \n \n1. Reference = 'Each Job'.A charge code is selected.UOM = 'Bill'.  -- > Qty will auto - replace as the number of bills in each job\
                     \n \n2. Reference = 'Each HBL/HAWB'.A charge code is selected.UOM = 'Container'.  -- > Qty will auto - replace as the number of containers in each HBL/HAWB",
        INV_GC_MinTotalAmount: "If the template line involves calculation & auto-place the 'QTY' when applying the template  (e.g. charge $1 per KG for each bill, the 'QTY' will auto-place as the house level's KG when applying the template), you could set a minimum or maximum total amount for it.\
                                \n \nWhen 'Min/ Max Total Amount' is filled, please input the corresponding fields as well('Min/Max Charge', 'Min/Max Print Description' and 'Min/Max UOM').All these fields will be applied together when it hits the minimum or maximum condition.",
        INV_GC_MaxTotalAmount: "If the template line involves calculation & auto-place the 'QTY' when applying the template  (e.g. charge $1 per KG for each bill, the 'QTY' will auto-place as the house level's KG when applying the template), you could set a minimum or maximum total amount for it.\
                                \n \nWhen 'Min/ Max Total Amount' is filled, please input the corresponding fields as well('Min/Max Charge', 'Min/Max Print Description' and 'Min/Max UOM').All these fields will be applied together when it hits the minimum or maximum condition.",
        INV_CR_MinTotalAmount: "If the template line involves calculation & auto-place the 'QTY' when applying the template  (e.g. charge $1 per KG for each bill, the 'QTY' will auto-place as the house level's KG when applying the template), you could set a minimum or maximum total amount for it.\
                                \n \nWhen 'Min/ Max Total Amount' is filled, please input the corresponding fields as well('Min/Max Charge', 'Min/Max Remarks' and 'Min/Max UOM').All these fields will be applied together when it hits the minimum or maximum condition.",
        INV_CR_MaxTotalAmount: "If the template line involves calculation & auto-place the 'QTY' when applying the template  (e.g. charge $1 per KG for each bill, the 'QTY' will auto-place as the house level's KG when applying the template), you could set a minimum or maximum total amount for it.\
                                \n \nWhen 'Min/ Max Total Amount' is filled, please input the corresponding fields as well('Min/Max Charge', 'Min/Max Remarks' and 'Min/Max UOM').All these fields will be applied together when it hits the minimum or maximum condition.",
        INV_GC_PrintDescriptionSelect: "Please leave 'Print Description' as blank if the default charge description of the charge code should be used when you are adopting the template onto an invoice",

        BMS_Prefer_Using_At: "It is optional to fill in for BMS - Marks & No. and Goods Description",
    },
    lbTmplibFieldTitle: {
        //Fixed Item
        lbLine: "#",

        //Record Title
        /**Web Invoice - Setting**/
        INV_Setting_Office: "Office",
        INV_Setting_AccountCode: "Account Code",
        INV_Setting_InvoiceType: "Invoice Type",

        /**Web Invoice - General Charge**/
        INV_GC_DrCr: "DR/CR",
        INV_GC_Reference: "Auto Create 1 General Charge Item For",
        INV_GC_Charge: "Charge",
        INV_GC_Charge_7048: "Charge",
        INV_GC_PrintDescription: "Print Description",
        INV_GC_PrintDescriptionSelect: "Print Description",
        INV_GC_Currency: "Currency",
        INV_GC_UnitPrice: "Unit Price",
        INV_GC_ExchangeRate: "",
        INV_GC_Qty: "QTY",
        INV_GC_UOM: "UOM",
        INV_GC_Remark: "Reference",

        INV_GC_MinCharge: "Min Charge",
        INV_GC_MinPrintDescription: "Min Print Description",
        INV_GC_MinPrintDescriptionSelect: "Print Description",
        INV_GC_MinUOM: "Min UOM",
        INV_GC_MinTotalAmount: "Min Total Amount",
        //INV_GC_MinTotalAmount_TaxInclusive: "Min Total Amount",

        INV_GC_MaxCharge: "Max Charge",
        INV_GC_MaxPrintDescription: "Max Print Description",
        INV_GC_MaxPrintDescriptionSelect: "Print Description",
        INV_GC_MaxUOM: "Max UOM",
        INV_GC_MaxTotalAmount: "Max Total Amount",
        //INV_GC_MaxTotalAmount_TaxInclusive: "Max Total Amount",

        /**Web Invoice - PL HBL**/
        INV_PLC_HBL: "HBL / HAWB",
        INV_PLHBL_CW: "",
        INV_PLHBL_Charge: "Charge",
        INV_PLHBL_PrintDescription: "Print Description",
        INV_PLHBL_Agent: "Profit/Loss Agent",
        INV_PLHBL_Agent_BuyCBM: "",
        INV_PLHBL_Agent_BuyQTY: "",
        INV_PLHBL_Agent_SellCBM: "",
        INV_PLHBL_Agent_SellQTY: "",
        INV_PLHBL_MR_BuyCBM: "",
        INV_PLHBL_MR_BuyQTY: "",
        INV_PLHBL_MR_SellCBM: "",
        INV_PLHBL_MR_SellQTY: "",


        /**Web Invoice - Cheque Request**/
        INV_CR_Reference: "Auto Create 1 General Charge Item For",
        INV_CR_Remark: "Reference",
        INV_CR_Charge: "Charge",
        INV_CR_PrintDescription: "Print Description",
        INV_CR_Description: "Remarks",
        INV_CR_Currency: "Currency",
        INV_CR_UnitPrice: "Unit Price",
        INV_CR_Qty: "QTY",
        INV_CR_UOM: "UOM",

        INV_CR_MinCharge: "Min Charge",
        INV_CR_MinPrintDescription: "Min Remarks",
        INV_CR_MinUOM: "Min UOM",
        INV_CR_MinTotalAmount: "Min Total Amount",

        INV_CR_MaxCharge: "Max Charge",
        INV_CR_MaxPrintDescription: "Max Remarks",
        INV_CR_MaxUOM: "Max UOM",
        INV_CR_MaxTotalAmount: "Max Total Amount",

        /**GC SOPMS**/
        INV_GCSOPMS_SETTING_ValidationGroup: "Validation Group",

        INV_GCSOPMS_IN_AccountCode: "Account Code",
        INV_GCSOPMS_IN_InvoiceType: "Invoice Type",
        INV_GCSOPMS_IN_OverseasLocal: "Overseas / Local",
        INV_GCSOPMS_IN_Office: "Job Office",
        INV_GCSOPMS_IN_Customer: "Customer",
        INV_GCSOPMS_IN_IssueToFrom: "Issue Party",
        INV_GCSOPMS_IN_NominationAgent: "Nomination Agent",
        INV_GCSOPMS_IN_ReleaseAgent: "Release Agent",
        INV_GCSOPMS_IN_Consignee: "Consignee",
        INV_GCSOPMS_IN_NotifyParty: "Notify Party",
        INV_GCSOPMS_IN_MOT: "Mode of Transport",
        INV_GCSOPMS_IN_Service: "Job Service",

        INV_GCSOPMS_IN_ETD_from: "ETD effective from",
        INV_GCSOPMS_IN_ETD_to: "ETD effective to",
        INV_GCSOPMS_IN_InvoiceDt_from: "Invoice Date effective from",
        INV_GCSOPMS_IN_InvoiceDt_to: "Invoice Date effective to",

        /**GC SOPMS**/
        INV_GCSOPMS_OUT_DrCr: "DR/CR",
        INV_GCSOPMS_OUT_BaseOn: "Auto Create 1 General Charge Item For",
        INV_GCSOPMS_OUT_Charge: "Charge",
        INV_GCSOPMS_OUT_PrintDescription: "Print Description",
        INV_GCSOPMS_OUT_Currency: "Currency",
        INV_GCSOPMS_OUT_UnitPrice: "Unit Price",
        INV_GCSOPMS_OUT_Qty: "QTY",
        INV_GCSOPMS_OUT_UOM: "UOM",
        INV_GCSOPMS_OUT_Reference: "Reference",

        INV_GCSOPMS_OUT_MinCharge: "Min Charge",
        INV_GCSOPMS_OUT_MinPrintDescription: "Min Print Description",
        INV_GCSOPMS_OUT_MinUOM: "Min UOM",
        INV_GCSOPMS_OUT_MinTotalAmount: "Min Total Amount",

        INV_GCSOPMS_OUT_MaxCharge: "Max Charge",
        INV_GCSOPMS_OUT_MaxPrintDescription: "Max Print Description",
        INV_GCSOPMS_OUT_MaxUOM: "Max UOM",
        INV_GCSOPMS_OUT_MaxTotalAmount: "Max Total Amount",

        INV_GCSOPMS_OUT_Override: "Override by",


        /***Local Invoice***/
        INV_GC_UnitPriceTax: "Unit Price",


        /***Print Option***/
        INV_PrintOption_Sea: "Print Options for Sea",
        INV_PrintOption_Air: "Print Options for Air",


        /***BMS***/
        BMS_Prefer_Using_At: "Prefer Using At",
        BMS_Description: "Marks & No. / Goods Description",
    },
    /***Customize Template Library***/

    /**** CR Share the same wordings **/
    Tmplib_INV_GC_Reference_ValueTo: {
        lb_1TO1: 'Job No. if only 1 job',
        lb_PERJOB: 'Job No.',
        lb_PERHBL: 'HBL/HAWB No. (If UOM = "KG"/"CBM" and Qty = 0, QTY will be replaced)',
        lb_PERCONTAINER: 'Container No./ Size',
        lb_PERCONTAINERSIZE: 'Container No./ Size',
        lb_PERCONTAINERSIZE_20: 'Container No./ Size',
        lb_PERCONTAINERSIZE_40: 'Container No./ Size',
        lb_PERCONTAINERSIZE_40HC: 'Container No./ Size',
        lb_PERCONTAINERSIZE_45: 'Container No./ Size',
        lb_PERCONTAINERSIZE_LCL: 'Container No./ Size',
        lb_PERCONTAINERSIZE_20HC: 'Container No./ Size',
        lb_PERCONTAINERSIZE_10: 'Container No./ Size',
        lb_PERCONTAINERSIZE_48: 'Container No./ Size',
        lb_PERCONTAINERSIZE_53: 'Container No./ Size',
        lb_GROUPBYHBL: 'Job No. (QTY will be replaced)',
        lb_GROUPBYHBL_PERMBL: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINER: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_20: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_40: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_40HC: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_45: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_LCL: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_20HC: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_10: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_48: 'Job No. (QTY will be replaced)',
        lb_GROUPBYCONTAINERSIZE_53: 'Job No. (QTY will be replaced)',
    },

    /***Dashboard Bar***/
    dashboardBar: {
        lbApply: 'Apply Once',
        lbSave: 'Save',
        lbDelete: 'Delete',
        lbNewFilter: 'Save Filter',
        lbEditFilter: 'Edit Filter',
        lbDeleteFilter: 'Delete Filter',
        lbFilterName: 'Filter Name',
        lbDateInputOption1: "1 Day",
        lbDateInputOption2: "2 Days",
        lbDateInputOption3: "1 week",
        lbDateInputOption4: "2 weeks",
        lbDateInputOption5: "1 month",
        lbDateInputOption6: "2 months",
        lbDateInputOption7: "3 months",
        lbBgColors: "Background Color",
        msgDeletePreference: 'Are you sure you want to delete this custom dashboard card?',
        msgMissingFilterDetail: 'Please fill in all the required fields in Customized Filter.',
        msg3CharactersRequired: 'The value must contains at least 3 characters.'
    },
    /***Filter Bar***/
    filterBar: {
        tlImgBtnFilter: "Customized Filter",
        msgClearCustomFilter: 'Sure to clear all custom filters?',
        lbField: 'Field',
        lbOperator: 'Operator',
        lbValue: 'Value',
        lbDateInputOption1: "1 Day",
        lbDateInputOption2: "2 Days",
        lbDateInputOption3: "1 week",
        lbDateInputOption4: "2 weeks",
        lbDateInputOption5: "1 month",
        lbDateInputOption6: "2 months",
        lbDateInputOption7: "3 months",
        msgMissingFilterDetail: 'Please fill in all the required fields in Customized Filter.',
        msg3CharactersRequired: 'The value must contains at least 3 characters.'
    },
    userMaintenance: {
        msgWarningDuplicateOPSID: "Below OPS ID exists in other user accounts. Please make sure the OPS ID and Office are entered correctly for this account. Click 'OK' to ignore this warning and continue to save the change.",
        msgListDuplcaiteOPSID: "OPS ID \"{{ops_user_id}}\" for Office \"{{party_office_code}}\": {{login_id}}"
    },
    consolidation: {
        lblConsolPlan: "Consol Plan",
        lblConsolApproval: "Consol Approval",
        lblLoadPlan: "Load Plan",
        lblShippingInstruction: "Container-PO Allocation",
        lblDocumentUpload: "Document Upload",
        msgFillInRequiredFields: "Please fill in all required fields.",
        lbConsolPlanInformation: "Consol Plan Information",
        lbShipmentsTable: "Selected Bookings",
        lbConsolidationType: "Consolidation Type",
        msgConsolidationTypeRequired: "Please select the Consolidation Type",
        lbOriginAgentContactOffice: "M+R Contact Office",
        lbOriginAgentContactOfficePlaceHolder: "M+R Contact Office",
        msgOriginAgentContactOfficeRequired: "Please select the M+R contact office",
        lbConsolidationTypeToolTip: "Agent Consol: Shipper delivers LCL cargo to M+R warehouse (CFS) at origin for physical consolidation with other applicable shipping orders. The HBLs in the Agent Consol are consigned to DIFFERENT consignees and are all handled by the SAME Release Agent.\n\nBuyer Consol: Shipper delivers LCL cargo to M+ R warehouse(CFS) at origin for physical consolidation with other applicable shipping orders.The HBLs in the Buyer Consol are consigned to the SAME Consignee.\n\nShipper Consol: Shippers arrange the loading at their own locations at origin(i.e.not physically via M+ R's CFS), seal and deliver the Shipper Consol to CY on \"said to contain\" basis. Shippers must provide letters of guarantee and takes full responsibilities for this type of operation.\n\nLCL / Coload Consol: Shipper delivers LCL cargo to M + R appointed coloader’s warehouse(CFS) for consolidation by coloader.The HBLs are either consigned to the SAME Consignee and / or handled by the SAME Release Agent.",
        lbOriginAgentContactOfficeToolTip: "Contact Office is the office to coordinate with CFS about the consolidation and it will be used as the contact office on the printouts.",
        msgFillInRequiredTabFields: "Please revise highlighted tab(s)",
        lbConsolidationHandlingOffice: "Consol Handling Office",
        lbConsolidationHandlingOfficePlaceHolder: "Consol Handling Office",
        lbConsolidationHandlingOfficeToolTip: "Please select the M+R office to handle this Consol Plan and coordinate with CFS about the consolidation. It is normally the M+R office of the POL.",
        msgConsolidationHandlingOfficeRequired: "Please input consol handling office",
        lbHBLOffice: "HBL Issued Office",
        lbHBLOfficePlaceHolder: "HBL Issued Office",
        lbHBLOfficePlaceToolTip: "M+R Office for issuing the HBL",
        lbConsolidationPOL: "Port of Loading (POL)",
        lbConsolidationPOLPlaceHolder: "Port of Loading (POL)",
        msgConsolidationPOLRequired: "Please select the Port of Loading (POL)",
        lbConsolidationPOD: "Port of Discharge (POD)",
        lbConsolidationPODPlaceHolder: "Port of Discharge (POD)",
        msgConsolidationPODRequired: "Please select the Port of Discharge (POD)",
        lbMRConsolReference: "M+R Consol Reference",
        lbMRConsolReferencePlaceHolder: "M+R Consol Reference",
        lbAgentReference: "Agent Consol Reference",
        lbAgentReferencePlaceHolder: "Agent Consol Reference",
        lbConsigneeReference: "Consignee Consol Reference",
        lbConsigneeReferencePlaceHolder: "Consignee Consol Reference",
        lbBuyerReference: "Buyer Consol Reference",
        lbBuyerReferencePlaceHolder: "Buyer Consol Reference",
        lbContainerSummarySize: "Consol Container Size",
        lbContainerSummarySizePlaceHolder: "Consol Container Size",
        lbFCLContainerSummarySize: "FCL Container Size",
        lbFCLContainerSummarySizePlaceHolder: "FCL Container Size",
        msgContainerSummarySizeRequired: "Please select the Container Size",
        lbContainerSummaryQty: "Qty",
        lbContainerSummaryQtyPlaceHolder: "Qty",
        msgContainerSummaryQtyRequired: "Please input qty",
        errConsolidationNotFound: "Consolidation ({{consolidation_no}}) is not found.",
        miCancelConsolidation: "Cancel Consolidation",
        msgSureToCancelConsolidation: "Are you sure you want to cancel the consol?",
        msgRestorePrevSearchCriteria: "The previous search criteria has been restored.",

        colLine: "#",
        colSoHdOffName: 'Booking Handling Office',
        colSoNo: "SO#",
        colConsolNo: "CONSOL#",
        colPoNo: "PO#",
        colCargoReadyDate: 'Cargo Ready Date',
        colContainerNo: 'Container Number',
        colLoadingSeq: 'Loading Seq.',
        colTotalCbm: 'Total CBM',
        colTotalKg: 'Total KG',
        colTotalPackageQty: 'Total Package Qty (UOM)',
        colTotalLCLItemQty: 'Total Item Qty (UOM) (LCL)',
        colTotalLCLPackageQty: 'Total Package Qty (UOM) (LCL)',
        colTotalLCLCbm: 'Total CBM (LCL)',
        colTotalLCLKg: 'Total KG (LCL)',
        colAllocatedPackageQty: 'Allocated Package Qty',
        colBookingContainer: 'Booking Container',
        colDangerousGoods: 'Dangerous Goods?',
        colShipper: 'Shipper',
        colConsignee: 'Consignee',
        colNotifyParty: 'Notify Party',
        colReleaseAgent: 'Release Agent',
        colReleaseAgentCode: 'Release Agent Code',
        colNominationAgent: 'Nomination Agent',     
        colPor: 'POR',
        colPol: 'POL',
        colEtd: 'ETD',
        colPod: 'POD',
        colConsolDate: 'Consol Date',
        colConsolStatus: 'Consol Status',
        colConsolStatusDate: 'Consol Status Date',
        colConsolApprovalStatus: 'Consol Approval Status',
        colConsolApprovalStatusDate: 'Consol Approval Status Date',
        colCargoReadyDateFrom: 'Cargo Ready Date From',
        colCargoReadyDateTo: 'Cargo Ready Date To',
        colConsolUpdatedBy: 'Consol Updated By',
        colConsolLastUpdatedTime: 'Consol Last Updated Time',
        colConsolApprovalReason: 'Consol Approval Reason',
        colConsolApprovalRemarks: 'Consol Approval Remarks',
        colConsolApprovalUpdatedBy: 'Consol Approval Updated By',
        colLatestStatus: 'Latest Status',
        colJobNo: 'Job#',
        colPodEta: 'POD ETA',
        colConsigneeReference: 'Consignee Consol Reference',
        colAgentReference: 'Agent Consol Reference',
        colOriginAgentReference: 'M+R Reference',
        colConsolHdOffName: 'Consol Handling Office',
        colAmendedBookingPending: 'Amended Booking Pending',
        colLoadPlanPendingAllocation: 'Load Plan Pending Allocation',
        colConsolApprovalUpdatedByProfile: 'Consol Approval Updated by Profile',
        colConsolCreatedBy: 'Consol Created By',

        lbLoadPlanInformation: 'Load Plan Information',
        lbMblNo: 'Carrier MBL No.',
        lbColoaderBlNo: 'Co-loader BL No.',
        lbColoaderBLNoPlaceholder: "Enter Co-loader BL No.",
        msgColoaderBlNoEnterBySO: 'Enter by SO',
        msgColoaderBlNoSameForAllSO: 'Same for all SO',
        lbSameForAllSO: 'Same for all SO',
        lbEnterBySO: 'Enter by SO',
        lbMovementPlaceholder: 'Select Movement',
        lbWarehouse: 'Warehouse',
        lbWarehouseContactPerson: 'Warehouse Contact Person',
        lbOriginAgentContactPerson: 'M+R Contact Person',
        lbWarehouseTel: 'Warehouse Contact Phone',
        lbOriginAgentTel: 'M+R Contact Phone',
        lbWarehouseFax: 'Warehouse Contact Fax',
        lbOriginAgentFax: 'M+R Contact Fax',
        lbWarehouseEmail: 'Warehouse Contact Email',
        lbOriginAgentEmail: 'M+R Contact Email',
        lbWarehouseRemarks: 'Instruction to Warehouse and Remarks',
        lbRemarks: 'Remarks to Destination',

        lbSOContainerAllocation: 'SO-Container Allocation',
        lbContainerSize: 'Container Size',
        msgContainerSizeRequired: 'Please select the Container Size',
        lbContainerTypeOwnership: 'Container Type/ Ownership',
        lbContainerType: 'Container Type',
        lbContainerOwnership: 'Container Ownership',
        msgContainerOwnershipRequired: 'Please select a Container Ownership',
        lbContainerNo: 'Container Number',
        lbSealNo: 'Seal Number',
        msgSealNoRequired: 'Please input Seal Number',
        lbContainerActualPackageQty: 'Container Actual Package Qty',
        lbContainerActualCBM: 'Container Actual CBM',
        lbContainerActualKG: 'Container Actual KG',
        lbCarrierSONo: 'Carrier Booking No.',
        lbMovement: 'Movement',
        lbPartOf: 'Part Of',
        lbCYGateIn: 'CY Gate-in',
        lbCFSReceived: 'CFS Received',
        msgCarrierSONoRequired: 'Please input Carrier Booking#',
        msgActualKGRequired: 'Please input Container Actual KG',
        lbVGM: 'VGM',
        lbSOContainerAllocationRemarks: 'Remarks',
        lbPackageQty: 'Package Qty',
        lbCBM: 'CBM',
        lbKG: 'KG',
        lbSandFill: 'Sand Fill',
        tlAppendDetailPackageInRemarkBtn: 'Append the detail Package Qty & UOM in Remarks',
        tlLoadPlanAllocationRecycleBin: 'Drag an allocated SO from the container and drop it into the rubbish bin to remove the allocation',
        tlAddAllUnallocatedBookings: 'Click to add all unallocated bookings into the container',
        msgAllocateAllRemainPackage: 'Are you sure to add all unallocated bookings into the container?',
        tlResetContainer: 'Click to clear all allocated bookings from the container',
        msgResetContainer: 'Are you sure to clear all allocated bookings from the container?',
        lbSOContainerDetailPackageWarning: 'The Package UOM is set as \'Unit\' in this Consol Container becuase there are multiple UOM selected in different SO.',
        lbAllocationPreference: 'Allocation Preference',
        lbExcludeFullyAllocatedSO: 'Exclude Fully Allocated Shipping Order',
        lbDoor: 'Door',
        msgInvalidContainerNo: 'Container No is not valid',
        msgInvalidConsolidationContainerSize: 'There is no available rate for {{consolidation_container_size}} in the M+R selected sailing option. Please select another container size or refresh the rate in Consol Plan first!',
        msgWarningUpdateSelectedRateContainers: 'The selected rate\'s container size of the M+ R selected sailing option will be updated.Please be reminded to request for consol approval for the updated rate again if needed.\nCurrent: {{origin_size}}\nUpdate: {{update_size}}',
        msgDuplicateContainerNo: 'Duplicate Container No',
        msgInvalidShipment: 'In {{so_no}}, the {{- field_name}} in SO does not match with the {{- field_name}} in this Consol Plan. Please check and adjust the {{- field_name}} in SO.',
        msgInvalidOMSShipment: 'OMS shipment {{oms_so_no}} cannot be consolidated with other shipment type {{so_no}}, please remove either of them',
        msgShipmentAlreadyConsol: '{{so_no}} had been linked with other Consol Plan {{consolidation_no}}.',
        msgShipmentNotConsolidatable: "In {{so_no}}, the 'Service' must be LCL and 'Can be Consol' must be Yes.",
        msgInvalidAmount: "Package Qty/ G.W. (KG)/ CBM must match between PO Details and Container Details",
        msgInvalidAmountForOMS: "Item Qty, Package Qty, G.W. (KG) and CBM must be fully allocated for OMS shipments",
        lbAllocationPreferenceTitle: 'Allocation Preference',
        lbAllocationPreference1: 'Drag and drop to allocate',
        lbAllocationPreference2: 'Click \'+\' to allocate',
        lbAllocateAllRemainedPackageQty: 'Allocate all remained Pacakge Qty',
        lbAlwaysAskMe: 'Always ask me',
        lbPleaseSelect: 'Please Select',

        lbPDATitle: 'Adjust the Package Qty, CBM, KGS',
        lbPDASONo: 'SO#',
        lbPDAContainerNo: 'Container#',
        lbPDASequenceNo: 'Loading Seq',
        lbPDAApproximateCBMAndKGS: 'Approximate the CBM and KGS based on the Package Qty',
        lbPDAPackageQty: 'Package Qty',
        lbPDACBM: 'CBM',
        lbPDAKGS: 'KGS',
        lbLegend: 'Legend',
        lbPDALegend1: 'Amount being allocated for this loading',
        lbPDALegend2: 'Remaining amount pending for allocation',
        lbPDALegend3: 'Allocated amount in other loading/ containers',
        msgIncorrectPackageQty: 'Package Qty can\'t equal to 0.',
        msgIncorrectCBM: 'CBM can\'t equal to 0.',
        msgIncorrectKGS: 'KGS can\'t equal to 0.',
        msgIncorrectCBM2: 'Balance of CBM is not fully allocated when balance Packaged Qty is fully allocated.',
        msgIncorrectKGS2: 'Balance of KGS is not fully allocated when balance Packaged Qty is fully allocated.',

        msgConsolAdviceError: 'Status \'Consol Plan Advice\' is not applicable to the Consol Plan as there is more than 1 proposed sailing. Please select "Approved" as the approval status and select one sailing option as the approved sailing.',

        colFile: 'File',
        colVisibleTo: "Visible To",

        lbVisibleTo: "Visible To",
        colApprovalStatus: "Approval Status",
        lbApprovalStatus: "Approval Status",
        lbNewRemarks: "New Remarks",
        lblTiming: "Timing as per customer's requirement",
        colRemarks: "Remarks",
        colRemarkType: "Remark Type",
        lblRemark: "Remark",
        lblDocumentType: "Document Type",
        lblFile: "File",
        lblFileSource: "File Source",
        lblDragOrUpload: "Select / Drag a file",

        msgWarningEditConsolApproval: "Please save the existing consol or cancel edit before performing Consol Approval",
        msgWarningEditLoadPlanOrContainerPOAllocation: "Please save the existing consol or cancel edit before performing Load Plan/Container-PO Allocation",
        msgWarningEditConsolPlan: "Please save the existing consol or cancel edit before performing Consol Plan",
        msgConsolShipmentAllocationSuccess: "{{bookings}} bookings are added into the Consol Plan successfully.",
        lbAutoRefreshShipmentAllocationTitle: "Latest booking information is found",
        lbAutoRefreshShipmentAllocationContent1: "The system found that there is an update on the booking information. The latest booking information for all selected bookings is now being displayed on this Consol Plan. Please check this Consol Plan again.\n",
        lbAutoRefreshShipmentAllocationContent2: "'SAVE' the Consol Plan again ",
        lbAutoRefreshShipmentAllocationContent3: "if you have made any changes on this Consol Plan since you are editing this Consol Plan.",

        lblPrintOption: "Print Option",
        lblAgentRef: "Agent Reference #",
        lblBuyerRef: "Buyer Reference #",
        lblWarehouseInfo: "Warehouse Information",
        lblRemarkDestination: "Remarks to Destination",
        lblGenerate: "Generate",
        lblOutputFormat: "Output Format",
        lblGenerateLoadPlan: "Generate Load Plan",
        lblLoadPlanHistory: "Load Plan History",

        msgSelectSailingSchedule: 'Please select from "Proposed Sailing for Consol Plan"',
        msgSelectRate: 'Please select a rate to approve. If there is any question, please contact BMS support.',

        smartSearchTooltip: "Enter CONSOL#, SO# to search. Click the Customized Filter for advance searching.\n\nOnce you entered the keyword in this field, you could click 'Tab' and then press 'Enter' to perform searching.",
        
        msgWarningRemarkExceedMaxImageSize: 'Image size cannot exceed {{maxImageSize}} MB.',
        msgWarningRemarkExceedMaxHTMLTextSize: 'Total size cannot exceed {{maxHTMLTextSize}} MB.',
        msgFinalWarningRemarkExceedMaxHTMLTextSize: 'Total Remark size (current: {{currentHTMLTextSize}}MB) cannot exceed {{maxHTMLTextSize}} MB. Please remove image(s).',
        msgRemarkExceedMaxTextLength: 'Total Remark length (current: {{currentTextLength}} characters) cannot exceed {{maxTextLength}} characters.',
        phRemarkbraftEditor: 'Please type your remarks or paste your screenshot here.'
    },
    consolPlanSummary: {
        lbSelectedBookingsSummary: "Selected Bookings Summary",
        lblBookings: 'Bookings',
        lblCBM: 'CBM',
        lblKG: 'KG',
        lblPackageQty: 'Package Qty',
        lblLCLCBM: 'CBM (LCL)',
        lblLCLKG: 'KG (LCL)',
        lblLCLPackageQty: 'Package Qty (LCL)',
        lblCargoReadyDate: 'Cargo Ready Date',
        lblConsignee: 'Consignee',
        lblReleaseAgent: 'Release Agent',
        lblNominationAgent: 'Nomination Agent',
        lblShipper: 'Shipper',
        lblDangerousGoods: 'Dangerous Goods?',
        lblResetBtn: 'Reset Selection',
        lblCreateConsolBtn: 'Create Consol Plan',
        lblAddToConsolBtn: 'Add to {{consolidation_no}}',
        lblTotalCBM: 'Total CBM',
        lblTotalKG: 'Total KG',
        lblTotalPackageQty: 'Total Package Qty'
    },
    lbConsolidationColorDesc: {
        SelectedBookingsYellow: 'The booking is not matched with the user office',
        SelectedBookingsOrange: 'SO amended on [{{updated_date}}] after the last save of Consol Plan',
        SelectedBookingsRed: 'A contradiction between SO data and Consol data is found. SO can\'t add into this Consol Plan unless the contradiction is eased.',
        SILCLContainerLineRed: 'The reminded LCL quantity is not being allocated into a Consol Container through \'Load Plan\'',
        SILCLConsolContainerLineRed: 'The total quantity, weight, and/or volume in this Consol Container for this SO are not the same as the sum of allocated PO',
        SILCLConsolContainerLineGreen: 'The total quantity, weight, and volume in this Consol Container for this SO are the same as the sum of allocated PO',
        SIFCLContainerRed: 'The total quantity, weight, and/or volume in this container are not the same as the sum of allocated PO',
        SIFCLContainerGreen: 'The total quantity, weight, and volume in this container are the same as the sum of allocated PO'
    },
    ConsolidationShippingInformation: {
        msgPOColumnRed: 'This PO is not being fully allocated/ over-allocated.',
        msgPOColumnGreen: 'This PO is being fully allocated.'
    },
    consolidationSelectedBookingMenuOption: {
        lblNoOption: 'No Next Activity',
        lblRemoveShipment: 'Remove from Consol Plan',
        lblSearchShipmentByConsignee: 'Find Related Booking by Consignee',
        lblSearchShipmentByCustomer: 'Find Related Booking by Customer',
        lblSearchShipmentByNominationAgent: 'Find Related Booking by Nomination Agent',
        lblSearchShipmentBySailing: 'Find Related Booking by Sailing'
    },
    prs: {
        msgDuplicatePartyDetected: 'Detected possible duplicate party. Are you sure want to create a new party?',
        headerShortName: 'Short Name',
        headerPartyCodeStatus: 'Party Code Status',
        headerPartyAccountCodeStatus: 'Party Account Code Status',
        headerPartyStatus: 'Party Status',
        btnSearchDuplicate: 'Search Duplicate',
        duplicateform: {
            lblName: 'Name'
        },
        lblGeneral: 'General',
        lblPartyDetail: 'Party Details',
        lblFullNameLocal: 'Full Name Local',
        lblRequesterRemark: 'Requester Remarks',
        msgRequesterRemarkRequired: "Please input Requester Remarks.",
        lblManagementRemark: 'Management Remarks',
        lblNameLock: 'Name Lock',
        lblAddressLock: 'Address Lock',
        lblNoHBL: 'Not allow to print HB/L',
        lblURL: 'URL',
        tipsRequestBy: 'The requestor for creating this Party',
        lblRequestBy: 'Request By',
        lblRequestOffice: 'Request Office',
        lblRequestDate: 'Request Date',
        lblAddressDetail: 'Address Details',
        lblAddressType: 'Address Type',
        msgAddressType: 'Please select an address type',
        lblAddressLangType: 'Address Language Type',
        msgAddressLangType: 'Please select an address language',
        lblState: 'State/ Province',
        lblContact: 'Contact',
        lblContactType: 'Contact Type',
        msgContactType: 'Please select a contact type',
        lblPosition: 'Position',
        lblPhone: 'Phone',
        msgPhone: 'Please input tel number',
        lblLedger: 'Ledger',
        btnFinanceApprove: 'Finance Approve',
        btnFinanceReject: 'Finance Reject',
        lblLedgerDetail: 'Ledger Detail',
        tipsSunSuffix: 'In SUN 4.x, an account code comprises of a SUN Ledger Prefix (e.g. 0129 for HKG, 1121 for WOFE) and unique suffix assigned to a specific party. The same party has the same Sun Suffix for its account codes across multiple ledgers.',
        lblSunSuffix: 'Sun Suffix',
        tipsLedgerCode: 'Refer to the ledger code assigned to the office (e.g. 7021 for HKG, 703x for WOFE branch).',
        lblLedgerCode: 'Ledger Code',
        lblInvoiceDetail: 'Invoice Details',
        msgLedgerCode: 'Please select Ledger Code.',
        lblSunacCode: 'Sun A/C Code',
        lblReject: 'Rejected',
        lblPending: 'Pending',
        lblBatchPaymentAR: 'Batch Payment AR',
        lblBatchPaymentAP: 'Batch Payment AP',
        lblBankDetail: 'Bank Details',
        lblOpeningOffice: 'Bank Name',
        msgOpeningOffice: 'Please input bank name.',
        lblAccountNo: 'Bank Account #',
        msgAccountNo: 'Please input bank account number.',
        lblAccountCurrency: 'Bank Account Currency',
        msgAccountCurrency: 'Please select bank account currency',
        lblCreditLimit: 'Credit Limit',
        lblPaymentTermAR: 'Payment terms code AR',
        lblPaymentTermAP: 'Payment terms code AP',
        lblUnsettledAmountAR: 'AR unsettled amount',
        lblUnsettledAmountAP: 'AP unsettled amount',
        lblCreditReminder: 'Credit Reminder',
        tipsCreditReminder: 'When the AR unsettled amount of this ledger for this party exceeds the Credit Reminder, a warning message will pop up when the user updates the status of the AR invoice in Web Invoice.',
        lblCreditAmount: 'Credit Limit',
        msgCreditAmount: 'Please input credit limit',
        lblCreditDeadline: 'Credit Deadline',
        lblCompanyID: 'Company ID',
        lblTaxTypeID: 'ID Type',
        lblTaxID: 'Tax ID',
        msgTaxID: 'Please input ID Number',
        lblCoRegNo: 'Co. Reg. No.',
        lblSSTRegNo: 'SST Reg. No.',
        lblBusinessLocation: 'Business Location',
        lblNPWPNIK: 'NPWP/NIK',
        lblTaxRate: 'Tax Rate %',
        lblInvoiceSubType: 'Invoice Sub-Type',
        lblOPSOffice: 'OPS Office',
        btnSelectAll: 'Select All',
        lblTargetOffice: 'Target Office',
        tipsHeaderTargetOffice: "Please mouse over to check the activated Shipper, Supplier, and Carrier roles for each Target Office.",
        tipsTargetOffice: 'Only applicable to local parties e.g. Shipper, Supplier. Only such parties are downloaded to Target Office OPS. Also only such parties would be availale for Web Invoice.',
        lblRole: 'Role',
        btnSave: 'Save',
        btnMgtApprove: 'Management Approve',
        btnMgtReject: 'Management Reject',
        btnAdminApprove: 'Admin Approve',
        btnAdminReject: 'Admin Reject',
        lblAgent: 'Agent',
        lblAgentCode: 'Agent Code',
        lblAgencyType: 'Agency Type',
        msgAgencyType: 'Offical Agent means an agent in the official agency record. Sub-Agent means an agent instructed by an Official Agent for receiving the consignment. For Other, the details should be described in the memo / remarks.',
        lblSubAgent: 'As Sub-Agent of',
        lblISF: 'ISF',
        lblISFAutoExcel: 'ISF Auto Excel',
        lblISFEmail: 'ISF Auto Excel Email',
        lblISFRemark: 'ISF Remark',
        lblConsignee: 'Consignee',
        lblConsigneeCode: 'Consignee Code',
        lblKeyAccount: 'Key Account',
        tipsKeyAccount: 'The Key Account for which this Consignee belongs to.',
        lblCutomer: 'Customer (SIS)',
        tipsCustomer: 'The customer validation profile applied to the SIS e-booking.',
        lblIsModeBooking: 'Enable Auto Registration (SIS)',
        tipsIsModeBooking: 'Enable system to automatically link SIS customer validation profile based on cofirmed e-booking.',
        lblSelfRegistration: 'Enable Self Registration (SIS)',
        lblSCAC: 'SCAC',
        lblDisplayName: 'Display Name',
        lblName: 'Name',
        lblEffectiveFrom: 'Effective From',
        lblEffectiveTo: 'Effective To',
        lblStatus: 'Status',
        msgSCACRequired: "Please input SCAC",
        msgDisplayNameRequired: "Please input display name",
        msgDuplicate: "Carrier already exists",

        lblEORI: 'EORI #',
        tipsEORI: '- The EORI# is a code that is available to all "Economic Operators" that are based in the European Union.\n\n- The EORI# is mandatory for Economic Operators that are filing customs entries for importation of goods into the EU, or are exporting from the EU.\n\n- Economic Operators not based in the EU must also file for an EORI# if they are importing goods into the EU, or are exporting from the EU.\n\n- Economic Operators not based in the EU must NOT file for an EORI# if their incoming goods are only transiting through the EU.\n\n- Economic Operators that are not based in the EU but are selling goods to / exporting goods to the EU must NOT file for an EORI#.\n\n- The format of an EORI# should be 2 letters followed by up to 15 alphanumeric characters (e.g.: DE123456789012345).\n\n- As the EORI# is not applicable to Switzerland, Norway and Northern Ireland, please enter the identification number (like the local Tax ID) as the EORI#.',
        msgInvalidEORI: 'Please input a valid EORI#! Valid EORI# format: 2 uppercase letters + up to 15 alphanumeric characters (e.g. DE12345678901234)',

        colDocumentUploaded: "Document Uploaded",
        colConfiguration: "Configuration",
        lblDocumentUpload: "Document Upload",
        lblDocumentUploadHistory: "Document Upload History",
        lblDocumentType: "Document Type",
        lblFile: "File",
        lblDragOrUpload: "Select / Drag a file",
        lblRemark: "Remark",
        lbVisibleTo: "Visible To",

        msgBeneficiaryNameContainsInvalidCharacter: 'Please input a valid Beneficiary Name! Valid Beneficiary Name: any characters excepts &_öä;<>#!"%=\\`´*~',
        msgBeneficiaryBicExccedsLenLimit: 'Please input a valid Beneficiary BIC! Valid Beneficiary BIC format: 8 or 11 characters (e.g. 12345678, 12345678910)',
        msgMissingEoriNo: 'EORI # is mandatory information for this party, please fill it in.',
        msgMissingUploadFile: 'Please upload file',

        msgEORIReminder: 'Mandatory for Agent and Consignee in EU, Switzerland, Norway and Northern Ireland',
    },
    notification: {
        lblFollow: 'Follow',
        lblFollowing: 'Following',
        lblUserDefaultSettingTitle: 'User Default Notification Setting',
        lblShipperOrderSettingTitle: 'Notification Setting for Shipping Order : ',
        lblMasterSettingTitleBooking: 'Booking',
        lblMasterSettingTitleConsolidation: 'Consolidation',
        lblMasterSettingTitleWebInvoice: 'Web Invoice',
        lblFollowTitle: 'You can change the setting by choosing:',
        lblFollowOption1: 'User Default',
        lblFollowOption2: 'Customized',
        lblDefaultSelection: 'Default Selection',
        lblSettingDesc1: 'You will be notified for the following events',
        lblSettingDesc2: 'By :',
        lblEventTableColumnEvent: 'Event',
        lblNotificationMethodOption1: 'In-app notification',
        lblNotificationMethodOption2: 'Email notification',
        bellButtonToolTip: 'Notification',
        lblNotificationListTitle: 'Notification',

        smartSearchTooltip: "Enter SO# or CONSOL# to search. \n\nOnce you entered the keyword in this field, you could click 'Tab' and then press 'Enter' to perform searching.",
        
        lblNotificationListReadAll: 'All',
        lblNotificationListUnread: 'Unread',

        lblNotificationListMenuOption1: 'Mark All as Read',
        lblNotificationListMenuOption2: 'Delete All',
        lblNotificationListMenuOption3: 'Refresh',
        lblNotificationListMenuOption4: 'User Default Setting',

        lblNotificationListItemMenuOption1a: 'Mark as Read',
        lblNotificationListItemMenuOption1b: 'Mark as Unread',
        lblNotificationListItemMenuOption2: 'Delete Notification',
        lblNotificationListItemMenuOption3a: 'Unfollow SO# ',

        msgSureToDeleteAll: "Sure to delete all notification?",

        lblNotificationViewAll: 'View all',

        msgUnfollowSuccess: 'Unfollow successfully.',
        msgUnfollowFail: 'Unfollow Fail. Please Contact Administrator.',
        lblMasterSettingErrorMessageHeaderBooking: 'Booking Session',
        lblMasterSettingErrorMessageHeaderConsolidation: 'Consolidation Session',
        lblMasterSettingErrorMessageHeaderWebInvoice: 'Web Invoice Session',
        msgSaveSettingSuccess: 'Setting Saved',
        msgSaveSettingFail: 'Save Setting Fail. Please Contact Administrator.',

        msgAtLeastOneEvent: 'Please select at leaset 1 notification event.',
        msgAtLeastOneNotificationMethod: 'Please select at leaset 1 notification method.',
        msgMissingEmailAddress: 'Please Fill in Email address.',
        msgInvalidEmailAddress: 'Invalid email',

        lblEditUserDefaultSettingTooltip: 'Edit',
        lblResetBtn: 'Reset Selection',

        // *** important for notification message *** 
        // considering old notification messages, please create new item instead of amending item value if item parmameters changed.
        //booking
        msgBookingNew: '{{updated_by_name}} created SO# {{so_no}}.',
        msgBookingAmend: '{{updated_by_name}} amended SO# {{so_no}}.',
        msgBookingConfirm: '{{updated_by_name}} confirmed SO# {{so_no}}.',
        msgBookingSubmitSI: '{{updated_by_name}} submitted SI for SO# {{so_no}}.',
        msgBookingCancelPending: 'Shipper cancelled SO# {{so_no}}.',
        msgBookingCancelBooking: '{{updated_by_name}} cancelled SO# {{so_no}}.',
        msgBookingRecoverBooking: '{{updated_by_name}} recovered SO# {{so_no}}.',
        msgBookingChangeCargoReadyDate: '{{updated_by_name}} changed Cargo Ready Date of SO# {{so_no}} from {{original_cargo_ready_date}} to {{new_cargo_ready_date}}',
        msgBookingChangeHandlingOffice: '{{updated_by_name}} changed Handling Office of SO# {{so_no}} from {{original_handling_office_name}} to {{new_handling_office_name}}',
        msgBookingUpdateSailingA1: '{{updated_by_name}} updated the sailing of SO# {{so_no}}. Another M+R Proposed Sailing Option is selected.',
        msgBookingUpdateSailingA2: '{{updated_by_name}} updated the sailing of SO# {{so_no}}. {{field_names}} are changed.',
        msgBookingUpdateSailingA2b: '{{updated_by_name}} updated the sailing of SO# {{so_no}}.',
        msgBookingUpdateSailingA2Detail: '{{field_name}} is changed from {{original_value}} to {{new_value}}.',
        msgBookingUpdateRouting: '{{updated_by_name}} updated the routing of SO# {{so_no}} ({{field_names}}).',
        msgBookingUpdateRoutingDetail: '{{field_full_name}} is changed from {{original_value}} to {{new_value}}.',
        msgBookingUploadBMSDocument: '{{updated_by_name}} uploaded document for SO# {{so_no}} ({{document_types}})',
        msgBookingDeleteBMSDocument: '{{updated_by_name}} deleted document for SO# {{so_no}} ({{document_types}})',
        msgBookingUpdateRate: '{{updated_by_name}} updated the {{rate_type}} which is selected in SO# {{so_no}}. Please refresh the rate in BMS if needed.',
        //booking approval
        msgBookingNewOperationRemark: '{updated_by_name} left an operation remark on SO# {so_no}.<br/><br/>{remark}',
        msgBookingNewPricingRemark: '{updated_by_name} left a pricing remark on SO# {so_no}.<br/><br/>{remark}',
        msgBookingRequestForApproval: '{{updated_by_name}} updated SO# {{so_no}} to Request for Approval.',
        msgBookingPendingCustomerApproval: '{{updated_by_name}} updated SO# {{so_no}} to Pending Customer\'s Approval.',
        msgBookingApproved: '{{updated_by_name}} approved SO# {{so_no}}.',
        msgBookingRejected: '{{updated_by_name}} rejected SO# {{so_no}}.',
        msgBookingBookingAdvice: '{{updated_by_name}} updated SO# {{so_no}} to Booking Advice.',
        msgBookingLinkToAConsolPlan: '{{updated_by_name}} linked SO# {{so_no}} to Consol Plan# {{consolidation_no}}',
        msgBookingDislinkToAConsolPlan: '{{updated_by_name}} unlinked SO# {{so_no}} from Consol Plan# {{consolidation_no}}',
        
        //consol
        msgConsolidationUpdateSailingA1: '{{updated_by_name}} updated the sailing of Consol# {{consolidation_no}}. Another M+R Proposed Sailing Option is selected.',
        msgConsolidationUpdateSailingA2: '{{updated_by_name}} updated the sailing of Consol# {{consolidation_no}}. {{field_names}} are changed.',
        msgConsolidationUpdateSailingA2b: '{{updated_by_name}} updated the sailing of Consol# {{consolidation_no}}.',
        msgConsolidationUpdateSailingA2Detail: '{{field_name}} is changed from {{original_value}} to {{new_value}}.',
        msgConsolidationUpdateRate: '{{updated_by_name}} updated the {{rate_type}} which is selected in Consol# {{consolidation_no}}. Please refresh the rate in BMS if needed.',
        msgConsolidationNewOperationRemark: '{updated_by_name} left a consol operation remark on Consol# {consolidation_no}.<br/><br/>{remark}',
        msgConsolidationNewPricingRemark: '{updated_by_name} left a consol pricing remark on Consol# {consolidation_no}.<br/><br/>{remark}',
        msgConsolidationRequestForConsolApproval: '{{updated_by_name}} updated Consol# {{consolidation_no}} to Request for Consol Approval.',
        msgConsolidationPendingCustomerConsolApproval: '{{updated_by_name}} updated Consol# {{consolidation_no}} to Pending Customer Consol Approval.',
        msgConsolidationApproved: '{{updated_by_name}} approved Consol# {{consolidation_no}}.',
        msgConsolidationRejected: '{{updated_by_name}} rejected Consol# {{consolidation_no}}.',
        msgConsolidationConsolPlanAdvice: '{{updated_by_name}} updated Consol# {{consolidation_no}} to Consol Plan Advice.',
        
        //web invoice
        lblMasterSettingTitleWebInvoice: 'Web Invoice',
        lblMasterSettingErrorMessageHeaderWebInvoice: 'Web Invoice Session',
        msgWebInvoiceApReferenceRecognized: 'AP Ref. {{ap_reference}} has been applied to {{invoice_no}} according to the uploaded document (file name: {{file_name}})',
        msgWebInvoiceApReferenceRecognizeError: 'The system can\'t locate and capture any AP Ref. from the uploaded document(file name: {{file_name}}) of {{invoice_no}}.Please check and update the AP Ref. manually',
        msgWebInvoiceMissingSunac: 'To post {{invoice_no}}, please apply a {{account_code}} Sun A/C Code for {{issued_party_code}} on the Ledger tab in PRS and make sure the application could be approved by the relevant party timely. Please take necessary action accordingly.',
        msgWebInvoiceGroupFailure1: 'GPE#{{group_no}} fails to export because the Base Currency Total Amount is negative (Invoice#{{invoice_no}})',
        msgWebInvoiceGroupFailure2: 'GPE#{{group_no}} fails to export because the Remarks of {{invoice_no}} is too long. Please shorten the Remarks or use the "简洁版" 备注格式 in the Header, and then export again.'
    },

    carrier: {
        colMRCarrierCode: 'M+R Carrier Code',
        colIsSCAC: 'Is SCAC Code',
        colAlliance: 'Alliance',
        colSCAC: 'SCAC Code',
        colCBSA: 'CBSA Code',
        colNACCS: 'NACCS Code',
        colDisplayName: 'Display Name',
        colFullName: 'Full Name',
        colIsSailingSchedule: 'Is Sailing Schedule',
        colEffectiveFrom: 'Effective From',
        colEffectiveTo: 'Effective To',
        colStatus: 'Status',
        colSFMCarrierSCAC: 'SFMCarrier SCAC Code',
        colCarrierType: 'Carrier Type',
        colWebsite: 'Website',

        lblMRCarrierCode: 'M+R Carrier Code',
        lblIsSCAC: 'Is SCAC Code',
        lblSCAC: 'SCAC Code',
        lblCBSA: 'CBSA Code',
        lblNACCS: 'NACCS Code',
        lblDisplayName: 'Display Name',
        lblFullName: 'Full Name',
        lblIsSailingSchedule: 'Is Sailing Schedule',
        lblAlliance: 'Alliance',
        lblEffectiveFrom: 'Effective From',
        lblEffectiveTo: 'Effective To',
        lblStatus: 'Status',
        lblSFMCarrierCode: 'SFMCarrier SCAC Code',
        lblCarrierType: 'Carrier Type',
        lblSea: 'Sea',
        lblWebsite: 'Website',

        msgDuplicateMRCarrierCode: "M+R Carrier Code must be unique.",
        msgMRCarrierCodeRequired: "Please input M+R Carrier Code.",
        msgMRCarrierCodeLenLtd: "M+R Carrier Code must be 2-4 characters long.",
        msgDuplicateCBSA: "CBSA already exists.",
        msgCBSALenLtd: "CBSA Code must be 4 characters long.",
        msgDuplicateNACCS: "NACCS already exists.",
        msgNACCSLenLtd: "NACCS Code must be 4 characters long.",
        msgDisplayNameRequired: "Please input display name",
        msgDuplicateDisplayName: "Carrier Display Name already exists.",
        msgDuplicateFullName: "Carrier Full Name already exists.",
        msgAllianceRequired: "Please Select Alliance.",
        
        msgDuplicate: "M+R Carrier Code must be unique.",
        msgRecordNotLatest: "This record is not updated. The record has been changed by another user.",
        msgInvalidEffectiveDate: "Effective To couldn't be earlier than the Effective From."
    },

    sailingSchedule: {
        lbCreateAdditionalSailing: "Create Additional Sailing",
        lbSelect: "Select",
        msgHardStopDuplicateSailing: "There is an existing sailing schedule already, please select from the system.",
        msgWarningDuplicateSailing: "Existing sailing scheduled found as below.\nPlease click \"Select\" to select the existing sailing.",
        msgWarningDuplicateSailing2: "Only if the above sailing is/are not suitable, click \"Create additional sailing\" to continue."
    },

    cargosphere: {
        lbEnvironmentCode: "Environment Code",
        lbLoginId: "Login ID",
        lbPassword: "Login Password",
        lbLoginStatus: "Login Status",
        msgLoading: "Loading...",
        msgEnterPassword: "Please enter cargosphere login password",
        msgInvalidLogin: "Invalid login",
        msgValidLogin: "Success",
    },

    rate: {
        msgUpdateLoginInfo: "Please click here to update rate login info to unlock Cargosphere rate search."
    }

};

export default en;